import { fetcher } from '@/api/fetcher';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import {
  ICreateUserPayload,
  IGetUser,
  IGetUserDealershipApplication,
  IGetUsers,
  ISearchUsersParams,
  IUpdateUserDealershipApplication,
  IUpdateUserPayload,
  IUpdateUserPermissions,
  IUserApplicationPermission,
  IUserEntity,
  IUserShortEntity,
  IUserShortParam,
} from '@/api/requests/types/user.types';
import { CUSTOMERS_URL_BASE_URL } from '@/api/requests/customers.request';

const BASE_URL = '/users';

const getUsers = async ({
  paginationParams,
  userSearchParam,
}: IGetUsers): Promise<IResponseWithPagination<IUserEntity>> => {
  return await fetcher.get(BASE_URL, {
    params: {
      ...paginationParams,
      ...userSearchParam,
    },
  });
};

const getUser = async ({ id, customerId }: IGetUser): Promise<IUserEntity> => {
  return await fetcher.get(`${BASE_URL}/${id}`, {
    ...(customerId && {
      params: { customerId },
    }),
  });
};

const getCurrentUser = async (): Promise<IUserEntity> => {
  return await fetcher.get(`${BASE_URL}/current`);
};

const createUser = async (data: ICreateUserPayload): Promise<void> => {
  return await fetcher.post(BASE_URL, data);
};

const updateUser = async ({ customerId, id, data }: IUpdateUserPayload): Promise<void> => {
  return await fetcher.put(`${BASE_URL}/${id}`, data, {
    ...(customerId && {
      params: { customerId },
    }),
  });
};

const updateUserPermission = async ({ customerId, id, data }: IUpdateUserPermissions): Promise<void> => {
  return await fetcher.put(`${CUSTOMERS_URL_BASE_URL}/${customerId}${BASE_URL}/${id}/permissions`, data);
};

const getUserPermission = async ({ id, customerId }: IGetUser): Promise<IUserApplicationPermission[]> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}${BASE_URL}/${id}/permissions`);
};

const searchUser = async ({ customerId, params }: ISearchUsersParams): Promise<IUserShortEntity[]> => {
  return await fetcher.get(`${BASE_URL}/search`, {
    params: {
      ...params,
      ...(customerId && { customerId }),
    },
  });
};

const updateUserDealershipPermission = async ({
  customerId,
  id,
  data,
}: IUpdateUserDealershipApplication): Promise<void> => {
  return await fetcher.put(`${CUSTOMERS_URL_BASE_URL}/${customerId}${BASE_URL}/${id}/dealerships`, data);
};

const getUserDealershipPermission = async ({ id, customerId }: IGetUser): Promise<IGetUserDealershipApplication[]> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}${BASE_URL}/${id}/dealerships`);
};

const deleteUser = async ({ id, customerId }: IUserShortParam): Promise<void> => {
  return await fetcher.delete(`${CUSTOMERS_URL_BASE_URL}/${customerId}${BASE_URL}/${id}`);
};

export const usersRequests = {
  getUsers,
  getUser,
  getCurrentUser,
  createUser,
  updateUserDealershipPermission,
  getUserDealershipPermission,
  getUserPermission,
  updateUserPermission,
  updateUser,
  searchUser,
  deleteUser,
};
