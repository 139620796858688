import React, { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  EExportProcessStorageName,
  IExportDocumentsProcessResponse,
  IExportDocumentsRequestParams,
} from '@/api/requests/types/documents.types';
import { useSnackbar } from 'notistack';
import { useDocumentsQueryHooks } from '@/api/query-hooks/documents.query-hooks';
import { EXPORT_ZIP } from '@/constants/upload-validation';
import { EExportProcessStatus } from '@/domains/document/types/document.types';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface IExportDocumentsProviderReturn {
  addProcess: (config: IExportDocumentsRequestParams) => void;
}

const ExportDocumentsContext = createContext<IExportDocumentsProviderReturn>({
  addProcess: (config: IExportDocumentsRequestParams) => {},
});

const ExportDocumentsProvider: FC<PropsWithChildren> = ({ children }) => {
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [processedList, setProcessedList] = useState<IExportDocumentsRequestParams[]>(
    JSON.parse(localStorage.getItem(EExportProcessStorageName.EXPORT_PROCESS) ?? '[]'),
  );

  const checkResults = useDocumentsQueryHooks.useGetExportDocumentsStatus(processedList);

  const removeProcessById = (processId: string, hasError = false) => {
    setProcessedList((prevState) => {
      return prevState.filter((item) => item.processId !== processId);
    });

    if (!hasError) {
      enqueueSnackbar('Generate zip file has been finished. Take a look your downloads', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
  };

  const addProcess = useCallback((config: IExportDocumentsRequestParams) => {
    setProcessedList((prevState) => [...prevState, config]);
    enqueueSnackbar('Export process is running. Download will be start automatically after generating zip', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  }, []);

  useEffect(() => {
    if (checkResults.length !== 0) {
      checkResults.forEach((item: UseQueryResult<IExportDocumentsProcessResponse, AxiosError>): void => {
        const isLoading = item.isLoading || item.isInitialLoading || item.isRefetching;
        const isErrors = !!item.error;
        const isDataHave = !isLoading && item.data && !isErrors;
        const isProcessCompleted =
          isDataHave &&
          (item.data.status === EExportProcessStatus.FAILED || item.data.status === EExportProcessStatus.COMPLETED);

        if (isErrors) {
          removeProcessById(item.error?.config?.params?.processId, isErrors);
          return;
        }

        if (isProcessCompleted) {
          if (downloadRef.current && item.data?.url) {
            downloadRef.current.href = item.data?.url;
            downloadRef.current.download = 'documents.zip';
            downloadRef.current.type = EXPORT_ZIP;
            downloadRef.current.click();
            removeProcessById(item.data.processId);
          }
        }
      });
    }
  }, [checkResults]);

  useEffect(() => {
    localStorage.setItem(EExportProcessStorageName.EXPORT_PROCESS, JSON.stringify([...processedList]));
  }, [processedList]);

  const value = useMemo((): IExportDocumentsProviderReturn => {
    return {
      addProcess,
    };
  }, [addProcess]);

  return (
    <ExportDocumentsContext.Provider value={value}>
      {children}
      <a ref={downloadRef} style={{ display: 'none' }} />
    </ExportDocumentsContext.Provider>
  );
};

export { ExportDocumentsProvider, ExportDocumentsContext };
