import { Components, Theme } from '@mui/material';

export function Alert(theme: Theme): Components {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          maxWidth: '73.5rem',
          margin: '0 auto',
          padding: '0.5rem 1rem',
          borderRadius: '.5rem',
          alignItems: 'center',
          svg: {
            color: 'inherit',
            '& > path': {
              stroke: 'currentColor',
            },
          },
          [theme.breakpoints.down('sm')]: {
            padding: '0.5rem',
          },
        },
        message: {
          padding: 0,
        },
        action: {
          color: 'inherit',
          padding: 0,
          svg: {
            color: 'inherit',
            '& > path': {
              stroke: 'currentColor',
            },
          },
        },
        filledError: {
          background: theme.palette.error.dark,
          color: theme.palette.error.main,
          '.MuiAlert-icon': {
            color: theme.palette.error.main,
          },
        },
      },
    },
  };
}
