import { Components, Theme } from '@mui/material';

export function Card(theme: Theme): Components {
  return {
    MuiCard: {
      defaultProps: {
        variant: 'elevation',
      },
      styleOverrides: {
        root: {
          padding: '1rem',
          borderRadius: '1rem',
          background: theme.palette.background.paper,
          boxShadow: theme.elevation[2],
          maxWidth: '73.5rem',
          margin: '0 auto',
          [theme.breakpoints.up('xl')]: {
            maxWidth: '100%',
          },
          [theme.breakpoints.down('md')]: {
            borderRadius: '.5rem',
          },
        },
      },
    },
  };
}
