import { Components, Theme } from '@mui/material';

export function Drawer(theme: Theme): Components {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          flexShrink: 0,
          boxShadow: theme.elevation[3],
          background: theme.palette.background.paper,
          padding: '1rem',
          borderRadius: '0 1rem 1rem 0',
          [theme.breakpoints.down('sm')]: {
            padding: '1rem .5rem',
            borderRadius: '0 .5rem .5rem 0',
          },
        },
        docked: {
          '.MuiDrawer-paper': {
            overflow: 'hidden',
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        wrapper: {
          width: '100%',
        },
        wrapperInner: {
          width: '100%',
        },
      },
    },
  };
}
