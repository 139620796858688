import { Components, Theme } from '@mui/material';

export function TablePagination(theme: Theme): Components {
  return {
    MuiTablePagination: {
      defaultProps: {
        showFirstButton: false,
        showLastButton: false,
        labelDisplayedRows: ({ from, to, count }) => {
          return `Showing results ${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
        },
        labelRowsPerPage: 'Records per page',
      },
      styleOverrides: {
        toolbar: {
          padding: theme.spacing(2, 0),
          minHeight: '5rem',
          justifyContent: 'space-between',
        },
        displayedRows: {
          color: theme.palette.secondary.main,
          fontWeight: 400,
        },
        selectLabel: {
          fontWeight: 400,
        },
        actions: {
          '.MuiIconButton-root': {
            border: `0.09375rem solid ${theme.palette.primary.main}`,
            borderRadius: '0.375rem',
            width: '3rem',
            height: '3rem',
            '&:hover': {
              background: theme.palette.primary.main,
              color: theme.palette.background.paper,
            },
          },
          '.Mui-disabled': {
            borderColor: theme.palette.text.disabled,
            color: theme.palette.text.disabled,
          },
          '.prevButton': {
            marginRight: '0.5rem',
          },
        },
      },
    },
  };
}
