import { fetcher } from '@/api/fetcher';
import {
  ICustomerErrors,
  IDocumentsError,
  IErrorDetails,
  IErrorsExists,
  IErrorsTotal,
  IGetDocumentErrorDetailsParams,
  IGetDocumentsErrorsParams,
  IGetErrorsParams,
  IResolveDuplicateErrorParams,
  IResolveErrorParams,
} from '@/api/requests/types/errors.types';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import { CUSTOMERS_URL_BASE_URL } from '@/api/requests/customers.request';

const BASE_URL = '/errors';

const getExistsErrors = async (): Promise<IErrorsExists> => {
  return await fetcher.get(`${BASE_URL}/exists`);
};

const getErrorsTotal = async ({ customerId, applicationId }: IGetErrorsParams): Promise<IErrorsTotal> => {
  return await fetcher.get(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}/documents${BASE_URL}/total`,
  );
};

const getErrors = async (): Promise<ICustomerErrors[]> => {
  return await fetcher.get(BASE_URL);
};

const getDocumentsErrors = async ({
  customerId,
  applicationId,
  params,
}: IGetDocumentsErrorsParams): Promise<IResponseWithPagination<IDocumentsError>> => {
  return await fetcher.get(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}/documents${BASE_URL}`,
    {
      params,
    },
  );
};

const getDocumentErrorDetails = async ({
  customerId,
  applicationId,
  documentId,
}: IGetDocumentErrorDetailsParams): Promise<IErrorDetails> => {
  return await fetcher.get(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}/documents/${documentId}${BASE_URL}`,
  );
};

const resolveDuplicate = async ({ params, action, fileName }: IResolveDuplicateErrorParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/${params.documentId}/error/duplicate`,
    { action, fileName },
  );
};

const resolveError = async ({ params, data }: IResolveErrorParams): Promise<string> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/${params.documentId}/error`,
    data,
  );
};

export const errorsRequests = {
  getErrors,
  getDocumentsErrors,
  getExistsErrors,
  getErrorsTotal,
  resolveDuplicate,
  resolveError,
  getDocumentErrorDetails,
};
