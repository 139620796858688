import { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setupInterceptor } from '@/api/fetcher';
import { useSnackbar } from 'notistack';

export const useAxiosInterceptor = (): void => {
  const { getAccessTokenSilently, isLoading, isAuthenticated, logout, error } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const onError = useCallback(
    (errorMessage: string): void => {
      enqueueSnackbar(errorMessage || 'Request failed. Please try again or contact us', {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    setupInterceptor({
      onError,
      getAccessTokenSilently,
      logout,
    });
  }, [getAccessTokenSilently, onError, isAuthenticated, logout]);

  useEffect(() => {
    if (error && !isLoading) onError(error?.message);
  }, [onError, error, isLoading]);
};
