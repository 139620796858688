import React, { PropsWithChildren } from 'react';

export const metadata = {
  icons: {
    icon: '/favicon.png',
  },
};

export const BaseLayout = ({ children }: PropsWithChildren) => {
  return <>{children}</>;
};
