import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  ICustomerErrors,
  IDocumentsError,
  IErrorDetails,
  IErrorsExists,
  IErrorsTotal,
  IGetDocumentErrorDetailsParams,
  IGetDocumentsErrorsParams,
  IGetErrorsParams,
  IResolveDuplicateErrorParams,
  IResolveErrorParams,
} from '@/api/requests/types/errors.types';
import { EErrorsQueryKey } from '@/api/query-keys/errors.query-keys';
import { errorsRequests } from '@/api/requests/errors.requests';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import { EUserRole } from '@/api/requests/types/user.types';

const useErrorsExists = (userRole?: EUserRole, options?: UseQueryOptions<IErrorsExists, Error, IErrorsExists>) => {
  return useQuery([EErrorsQueryKey.ERRORS], errorsRequests.getExistsErrors, {
    enabled: userRole === EUserRole.ADT_ADMIN || userRole === EUserRole.UPLOADER,
  });
};

const useTotalError = (
  { customerId, applicationId }: IGetErrorsParams,
  options?: UseQueryOptions<IErrorsTotal, Error, IErrorsTotal>,
) => {
  return useQuery(
    [EErrorsQueryKey.ERRORS, `${applicationId}, ${customerId}`],
    () => errorsRequests.getErrorsTotal({ customerId, applicationId }),
    { enabled: !!customerId && !!applicationId, cacheTime: 0 },
  );
};

const useErrors = (options?: UseQueryOptions<ICustomerErrors[], Error, ICustomerErrors[]>) => {
  return useQuery([EErrorsQueryKey.ERRORS, EErrorsQueryKey.CUSTOMER_ERRORS], errorsRequests.getErrors);
};

const useDocumentsErrors = (
  { customerId, applicationId, params }: IGetDocumentsErrorsParams,
  options?: UseQueryOptions<
    IResponseWithPagination<IDocumentsError>,
    Error,
    IResponseWithPagination<IDocumentsError>,
    string[]
  >,
) => {
  return useQuery(
    [
      EErrorsQueryKey.ERRORS,
      EErrorsQueryKey.DOCUMENTS_ERRORS,
      `${applicationId}, ${customerId}`,
      JSON.stringify(params),
    ],
    () => errorsRequests.getDocumentsErrors({ customerId, applicationId, params }),
    { enabled: !!customerId && !!applicationId },
  );
};

const useDocumentErrorDetails = (
  { customerId, applicationId, documentId }: IGetDocumentErrorDetailsParams,
  options?: UseQueryOptions<IErrorDetails, Error, IErrorDetails, string[]>,
) => {
  return useQuery(
    [EErrorsQueryKey.DOCUMENT_ERRORS_DETAILS, `${applicationId}, ${customerId}, ${documentId}`],
    () => errorsRequests.getDocumentErrorDetails({ customerId, applicationId, documentId }),
    { enabled: !!customerId && !!applicationId && !!documentId },
  );
};

const useResolveDuplicate = (
  options?: UseMutationOptions<void, Error, IResolveDuplicateErrorParams>,
): UseMutationResult<void, Error, IResolveDuplicateErrorParams> => {
  const queryClient = useQueryClient();

  return useMutation(errorsRequests.resolveDuplicate, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EErrorsQueryKey.ERRORS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useResolveError = (
  options?: UseMutationOptions<string, Error, IResolveErrorParams>,
): UseMutationResult<string, Error, IResolveErrorParams> => {
  const queryClient = useQueryClient();

  return useMutation(errorsRequests.resolveError, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EErrorsQueryKey.ERRORS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

export const useErrorsQueryHooks = {
  useErrors,
  useErrorsExists,
  useResolveDuplicate,
  useTotalError,
  useDocumentErrorDetails,
  useResolveError,
  useDocumentsErrors,
};
