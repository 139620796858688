import { EIndexType } from '@/api/requests/types/index.types';
import { IDocumentIndexData } from '@/api/requests/types/documents.types';

export enum EDocumentTab {
  EMAIL_DOCUMENTS = 'EMAIL_DOCUMENTS',
  VIEW_HISTORY = 'VIEW_HISTORY',
  VIEW_NOTES = 'VIEW_NOTES',
  ADD_NOTES = 'ADD_NOTES',
  EDIT_INDEX_VALUES = 'EDIT_INDEX_VALUES',
  APPEND_DOCUMENT = 'APPEND_DOCUMENT',
}

export enum EFileType {
  ZIP = 'application/zip',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum EAppendDocumentType {
  BEGINNING = 'BEGINNING',
  ENDING = 'ENDING',
  BEFORE_PAGE = 'BEFORE_PAGE',
  REPLACE = 'REPLACE',
}

export interface IDocumentRow extends IDocumentIndexData {
  id: number;
  dealershipName: string;
}

export interface IIndexSearchOption {
  key: string;
  value: string;
}

export enum ESelectedDocumentsAction {
  PRINT = 'PRINT',
  EMAIL = 'EMAIL',
  VIEW_SELECTED = 'VIEW_SELECTED',
  EXPORT_ZIP = 'EXPORT_ZIP',
  MERGE = 'MERGE',
  EXPORT_EXCEL = 'EXPORT_EXCEL',
  EXPORT_ALL_EXCEL = 'EXPORT_ALL_EXCEL',
  EXPORT_ALL_ZIP = 'EXPORT_ALL_ZIP',
  CLEAR_SELECTED = 'CLEAR_SELECTED',
  BULK_DELETE = 'BULK_DELETE',
}

export enum EDocumentFilterType {
  CONTAINS = 'CONTAINS',
  EQUAL = 'EQUAL',
  LESS = 'LESS',
  MORE = 'MORE',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
  MORE_OR_EQUAL = 'MORE_OR_EQUAL',
  BEGINS_WITH = 'BEGINS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  RANGE = 'RANGE',
  DATE_RANGE = 'DATE_RANGE',
  LIST = 'LIST',
  EMPTY = 'EMPTY',
}

export enum EExportProcessStatus {
  PREPARING = 'PREPARING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type TDocumentTabName = {
  [key in EDocumentTab]: string;
};

export type TFilterTypeMenuName = {
  [key in EDocumentFilterType]: string;
};

export interface IFilterTypeMenuItem {
  name: string;
  value: EDocumentFilterType;
}

export type TDocumentFilterTypeByIndexType = {
  [key in EIndexType]: IFilterTypeMenuItem[];
};

export type TDocumentDefaultFilterTypeByIndexType = {
  [key in EIndexType]: EDocumentFilterType;
};
