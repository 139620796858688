import { Components, Theme } from '@mui/material';
import { ReactComponent as Arrow } from '@assets/Down.svg';

export function Table(theme: Theme): Components {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `0.0625rem solid ${theme.palette.grey[600]}`,
          borderRadius: '0.5rem',
          background: theme.palette.background.paper,
          '&::-webkit-scrollbar': {
            width: '.25rem',
            height: '.5rem',
            backgroundColor: theme.palette.grey[600],
            borderRadius: '.625rem',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#8E96AE',
            outline: 'none',
            borderRadius: '.625rem',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
        stickyHeader: {
          '.MuiTableCell-stickyHeader': {
            position: 'sticky',
            background: theme.palette.grey[100],
            '&:first-of-type': {
              zIndex: '10',
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: theme.palette.grey[100],
        },
        root: {
          borderBottom: `0.0625rem solid ${theme.palette.grey[600]}`,
          background: theme.palette.background.paper,
          '&:nth-of-type(even)': {
            background: theme.palette.grey[300],

            '.MuiSkeleton-root': {
              background: theme.palette.background.paper,
            },
          },
          '&:last-child': {
            border: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.5rem',
          position: 'relative',
          padding: '1rem',
          border: 'none',
          maxWidth: '12.5rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&:before': {
            content: '""',
            position: 'absolute',
            height: '0.875rem',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            borderRight: `0.0625rem solid ${theme.palette.grey[600]}`,
          },
          '&:last-child': {
            '&:before': {
              content: 'none',
            },
          },
          [theme.breakpoints.down('sm')]: {
            padding: '.75rem 1rem',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: theme.palette.grey[100],
        },
      },
    },
    MuiTableSortLabel: {
      defaultProps: {
        IconComponent: Arrow,
        direction: 'desc',
        active: true,
      },
      styleOverrides: {
        root: {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          '&:hover': {
            color: 'inherit',
          },
        },
        icon: {
          color: theme.palette.secondary.main,
          '& > path': {
            stroke: theme.palette.secondary.main,
          },
          '@media print': {
            display: 'none',
          },
        },
      },
    },
  };
}
