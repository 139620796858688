import { Components, Theme } from '@mui/material';
import { ReactComponent as DownArrow } from '@assets/Down.svg';
import { ReactComponent as Radio } from '@assets/RadioUncheck.svg';
import { ReactComponent as RadioCheck } from '@assets/RadionCheck.svg';
import { ReactComponent as Checkbox } from '@assets/CheckboxUncheck.svg';
import { ReactComponent as CloseIcon } from '@assets/Close.svg';
import { ReactComponent as CheckboxCheck } from '@assets/CheckboxCheck.svg';
import React from 'react';

export function Input(theme: Theme): Components {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: '0.5rem 0 0',
          fontWeight: 400,
          color: 'text.secondary',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          minHeight: '3rem',
          height: '3rem',
          minWidth: '0.8125rem',
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.5rem',
          borderColor: theme.palette.grey[600],
          borderRadius: '0.375rem',
          background: theme.palette.background.paper,
          padding: 0,
          '&.Mui-focused': {
            outline: 'none',
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: '0.0625rem',
            },
          },
          '.MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[600],
          },
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
          '&.Mui-disabled': {
            background: theme.palette.grey[300],
            color: theme.palette.secondary.main,
            textFillColor: theme.palette.secondary.main,
          },
        },
        multiline: {
          height: 'auto',
          '& > textarea': {
            height: '6.5rem !important',
            overflowY: 'auto !important',
            margin: '.5rem .25rem .5rem .5rem',
            padding: 0,
            '&::-webkit-scrollbar': {
              width: '.25rem',
              backgroundColor: theme.palette.grey[600],
              borderRadius: '.625rem',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#8E96AE',
              outline: 'none',
              borderRadius: '.625rem',
            },
          },
        },
        input: {
          minHeight: '3rem',
          height: '3rem',
          minWidth: '0.8125rem',
          borderColor: theme.palette.grey[600],
          padding: '0.75rem 0.5rem',
          background: 'transparent',
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: theme.palette.secondary.main,
            opacity: 1,
          },
          '&.Mui-disabled': {
            color: theme.palette.secondary.main,
            textFillColor: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'none',
          borderBottom: `0.0625rem solid ${theme.palette.grey[600]}`,
          padding: theme.spacing(1),
          '&.Mui-selected': {
            background: 'none',
            color: theme.palette.primary.main,
          },
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& > .MuiFormLabel-root': {
            margin: 0,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
        IconComponent: (props) => <DownArrow {...props} />,
      },
      styleOverrides: {
        icon: {
          right: '0.5rem',
          top: 'calc(50% - 0.75rem)',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <DownArrow />,
        clearIcon: <CloseIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          height: 'inherit',
          '.MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0 0.5rem',
          },
        },
        clearIndicator: {
          color: theme.palette.grey[200],
          width: '1.5rem',
          height: '1.5rem',
        },
        inputRoot: {
          padding: 0,
          minHeight: '3rem',
          height: 'auto',
        },
        popupIndicator: {
          color: theme.palette.grey[200],
          width: '1.5rem',
          height: '1.5rem',
        },
        listbox: {
          borderRadius: 0,
          background: theme.palette.background.paper,
          padding: 0,
          maxHeight: '20rem',
          margin: '.5rem .25rem .5rem 0',
          '.MuiAutocomplete-option': {
            padding: '0.5rem',
            borderColor: theme.palette.grey[600],
            '&[aria-selected="true"], &[aria-selected="true"].Mui-focused': {
              background: 'none',
              color: 'text.primary',
            },
          },
          '&::-webkit-scrollbar': {
            width: '.25rem',
            backgroundColor: theme.palette.grey[600],
            borderRadius: '.625rem',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#8E96AE',
            outline: 'none',
            borderRadius: '.625rem',
          },
        },
        paper: {
          borderRadius: '0.5rem',
          background: theme.palette.background.paper,
          boxShadow: theme.elevation[1],
          overflowX: 'hidden',
        },
        option: {
          background: 'none',
          borderBottom: `0.0625rem solid ${theme.palette.grey[600]}`,
          padding: theme.spacing(0.5),
          '&.Mui-selected': {
            background: 'none',
            color: theme.palette.primary.main,
          },
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0.5rem',
          background: theme.palette.background.paper,
          boxShadow: theme.elevation[1],
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          color: theme.palette.text.primary,
          '& > .MuiFormControlLabel-label.Mui-disabled': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          minHeight: '3rem',
          minWidth: '0.8125rem',
          borderColor: theme.palette.grey[600],
          padding: '0.75rem 0.5rem',
          background: theme.palette.background.paper,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '3.5rem',
          height: '2rem',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          margin: '0.25rem',
        },
        switchBase: {
          padding: 0,
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
            borderColor: theme.palette.primary.main,
          },
          '&.Mui-checked > .MuiSwitch-thumb': {
            opacity: 1,
            transform: 'translateX(0.25rem)',
          },
          '&:hover': {
            background: 'transparent',
            boxShadow: 'none !important',
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            borderColor: theme.palette.grey[600],
            background: theme.palette.grey[300],
            opacity: 1,
          },
          '&.Mui-disabled > .MuiSwitch-thumb': {
            background: theme.palette.grey[600],
          },
        },
        thumb: {
          padding: 0,
          width: '1.75rem',
          height: '1.75rem',
          background: theme.palette.background.paper,
          marginTop: '0.125rem',
          marginLeft: '0.125rem',
          boxShadow: 'none !important',
        },
        track: {
          borderRadius: '1rem',
          opacity: 1,
          background: theme.palette.grey[100],
          border: `0.09375rem solid ${theme.palette.grey[600]}`,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
        icon: <Radio />,
        checkedIcon: <RadioCheck />,
      },
      styleOverrides: {
        root: {
          padding: '0.25rem',
          '&.Mui-disabled > svg > rect': {
            stroke: theme.palette.grey[600],
          },
          '&.Mui-disabled > svg > circle': {
            fill: theme.palette.grey[500],
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
        icon: <Checkbox />,
        checkedIcon: <CheckboxCheck />,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: '0.25rem',
          '&.Mui-disabled.Mui-checked > svg': {
            borderRadius: '0.3125rem',
            border: `0.0625rem solid ${theme.palette.grey[600]}`,
          },
          '&.Mui-disabled > svg > rect': {
            fill: theme.palette.grey[500],
          },
          '&.Mui-disabled > svg > path': {
            stroke: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'none',
          position: 'static',
          color: theme.palette.text.primary,
          marginBottom: '0.5rem',
        },
        asterisk: {
          color: theme.palette.error.main,
        },
      },
    },
  };
}
