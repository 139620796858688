import { createTheme } from '@mui/material/styles';

import { palette } from './palette';
import { typography } from './typography';
import { overrides } from './overrides';
import { elevation } from '@/theme/elevation';
import { breakpoints } from '@/theme/breakpoints';

const theme = createTheme({
  breakpoints,
  palette,
  typography,
  elevation,
});

theme.components = {
  ...overrides(theme),
  MuiCssBaseline: {
    styleOverrides: `
        html, body {
          font-family: 'Inter, sans-serif';
        }
      `,
  },
};

export { theme };
