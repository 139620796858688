import { Components, Theme } from '@mui/material';

export function Button(theme: Theme): Components {
  return {
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
        disableRipple: true,
      },
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            background: theme.palette.text.disabled,
            color: theme.palette.grey[800],
          },
        },
        root: {
          textTransform: 'none',
          minWidth: '12.5rem',
          minHeight: '3rem',
          fontWeight: 400,
          borderRadius: '0.375rem',
          borderWidth: '0.09rem',
          '& > svg': {
            color: 'inherit',
            '& > path': {
              stroke: 'currentColor',
            },
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: '12rem',
          },
        },
        startIcon: {
          '& > svg': {
            color: 'inherit',
            '& > path': {
              stroke: 'currentColor',
            },
          },
        },
        outlinedPrimary: {
          borderColor: theme.palette.primary.main,
          color: theme.palette.text.primary,

          '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.background.paper,
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.text.disabled,
            color: theme.palette.text.disabled,
          },
        },
        outlinedError: {
          borderColor: theme.palette.error.main,
          color: theme.palette.error.main,
          '&:hover': {
            background: theme.palette.error.main,
            color: theme.palette.background.paper,
          },
        },
        outlinedSizeSmall: {
          minWidth: '3rem',
        },
        sizeSmall: {
          minWidth: '7.5rem',
          fontWeight: 'inherit',
          fontSize: 'inherit',
          padding: '0.5rem',

          [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: '2rem',
          width: '2rem',
          padding: 0,
          transition: theme.transitions.create('color'),
          '& > svg': {
            color: 'inherit',
            '& > path': {
              stroke: 'currentColor',
            },
          },
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
        sizeMedium: {
          height: '3rem',
          width: '3rem',
        },
        sizeLarge: {
          height: '4rem',
          width: '4rem',
        },
      },
    },
  };
}
