import { Components, Theme } from '@mui/material';

export function ListItemButton(theme: Theme): Components {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          color: 'text.secondary',
          transition: theme.transitions.create('color'),
          '& > svg': {
            color: 'inherit',
            '& > path': {
              stroke: 'currentColor',
            },
          },
          '&:hover, &:focus': {
            color: theme.palette.text.primary,
            background: 'none',
          },
          '&.Mui-selected': {
            color: theme.palette.text.primary,
            background: theme.palette.grey[100],
          },
        },
      },
    },
  };
}
