import { EUserPermission } from '@/types/user-permission';
import { ICustomerParam, IPaginationParams, ISearchParams } from '@/api/requests/types/customer.types';
import { IApplicationShortEntity } from '@/api/requests/types/application.types';
import { IDealershipEntity } from '@/api/requests/types/dealership.types';

export enum EUserRole {
  ADT_ADMIN = 'ADT_ADMIN',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  USER = 'USER',
  UPLOADER = 'UPLOADER',
}

export enum EUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IUserShortEntity {
  id: number;
  userName: string;
}

export interface ISearchUsersParams {
  customerId: number | null;
  params: ISearchParams;
}

export interface ICurrentUserPermission {
  applicationId: number;
  applicationName: string;
  permissions: EUserPermission[];
}

export interface IUserEntity extends IUserShortEntity {
  customerId: number | null;
  email: string;
  firstName: string;
  lastName: string;
  role: EUserRole;
  status: EUserStatus;
  applicationIdPermissions: ICurrentUserPermission[] | null;
  mfaEnabled: boolean;
  recaptchaEnabled: boolean;
}

export interface IUserApplicationPermission {
  applicationId: number;
  parameters: EUserPermission[];
}

export interface IDealershipApplicationPermission {
  applicationId: number;
  dealershipIds: number[];
}

export interface ICreateUserDto {
  userName: string;
  customerId?: number | null;
  email: string;
  firstName: string;
  lastName: string;
  role: EUserRole;
  status: EUserStatus;
}

export interface ICreateUserPayload {
  user: ICreateUserDto;
  permissions: IUserApplicationPermission[];
  dealershipsApplications: IDealershipApplicationPermission[];
}

export interface IUpdateUserEntity {
  userName: string;
  firstName: string;
  lastName: string;
  role: EUserRole;
  status: EUserStatus;
  mfaEnabled?: boolean;
  recaptchaEnabled?: boolean;
}

export interface IUpdateUserPayload extends ICustomerParam {
  id: number;
  data: IUpdateUserEntity;
}

export interface IGetUser {
  customerId?: string;
  id?: string;
}

export interface IUpdateUserPermissions extends ICustomerParam {
  id: number;
  data: IUserApplicationPermission[];
}

export interface IUpdateUserDealershipApplication extends ICustomerParam {
  id: number;
  data: IDealershipApplicationPermission[];
}

export interface IUserSearchParams {
  customerId?: number;
  firstName?: string;
  lastName?: string;
  userName?: string;
  status?: EUserStatus;
  userRole?: EUserRole;
}

export interface IGetUsers {
  paginationParams: IPaginationParams;
  userSearchParam: IUserSearchParams | null;
}

export interface IGetUserDealershipApplication {
  application: IApplicationShortEntity;
  dealerships: IDealershipEntity[];
}

export interface IUserShortParam extends ICustomerParam {
  id: number;
}
