import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import {
  ICreateUserPayload,
  IGetUser,
  IGetUserDealershipApplication,
  IGetUsers,
  ISearchUsersParams,
  IUpdateUserDealershipApplication,
  IUpdateUserPayload,
  IUpdateUserPermissions,
  IUserApplicationPermission,
  IUserEntity,
  IUserShortEntity,
  IUserShortParam,
} from '@/api/requests/types/user.types';
import { usersRequests } from '@/api/requests/users.request';
import { EUsersQueryKey } from '@/api/query-keys/users.query-keys';
import { EDealershipsQueryKey } from '@/api/query-keys/dealerships.query-keys';

const useGetUsers = (
  params: IGetUsers,
  options?: UseQueryOptions<
    IResponseWithPagination<IUserEntity>,
    Error,
    IResponseWithPagination<IUserEntity>,
    string[]
  >,
) => {
  return useQuery([EUsersQueryKey.USERS, JSON.stringify(params)], () => usersRequests.getUsers(params), {
    ...options,
  });
};

const useGetUser = (
  { customerId, id }: IGetUser,
  options?: UseQueryOptions<IUserEntity, Error, IUserEntity, string[]>,
) => {
  return useQuery(
    [EUsersQueryKey.USERS, `${id}`, `${customerId}`],
    () =>
      usersRequests.getUser({
        id: id ?? '',
        customerId: customerId ?? '',
      }),
    {
      enabled: !!id,
      ...options,
    },
  );
};

const useCurrentUser = (options?: UseQueryOptions<IUserEntity, Error, IUserEntity>) => {
  return useQuery([EUsersQueryKey.CURRENT_USER], usersRequests.getCurrentUser);
};

const useGetUserDealershipApplication = (
  { id, customerId }: IGetUser,
  options?: UseQueryOptions<IGetUserDealershipApplication[], Error, IGetUserDealershipApplication[], string[]>,
) => {
  return useQuery(
    [EDealershipsQueryKey.DEALERSHIPS, EUsersQueryKey.USER_DEALERSHIP_APPLICATION, `${id}`, `${customerId}`],
    () =>
      usersRequests.getUserDealershipPermission({
        id: id ?? '',
        customerId: customerId ?? '',
      }),
    {
      enabled: !!id && !!customerId,
      ...options,
    },
  );
};

const useGetUserPermissions = (
  { id, customerId }: IGetUser,
  options?: UseQueryOptions<IUserApplicationPermission[], Error, IUserApplicationPermission[], string[]>,
) => {
  return useQuery(
    [EUsersQueryKey.USER_PERMISSIONS, `${id}`, `${customerId}`],
    () =>
      usersRequests.getUserPermission({
        id: id ?? '',
        customerId: customerId ?? '',
      }),
    {
      enabled: !!id && !!customerId,
      ...options,
    },
  );
};

const useCreateUser = (
  options?: UseMutationOptions<void, Error, ICreateUserPayload>,
): UseMutationResult<void, Error, ICreateUserPayload> => {
  const queryClient = useQueryClient();

  return useMutation(usersRequests.createUser, {
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EUsersQueryKey.USERS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useSearchUser = (
  { params, customerId }: ISearchUsersParams,
  options?: UseQueryOptions<IUserShortEntity[], Error, IUserShortEntity[], string[]>,
) => {
  return useQuery(
    [EUsersQueryKey.USERS, EUsersQueryKey.SEARCH_USER, JSON.stringify(params)],
    () => usersRequests.searchUser({ customerId, params }),
    {
      ...options,
    },
  );
};

const useUpdateUser = (
  options?: UseMutationOptions<void, Error, IUpdateUserPayload>,
): UseMutationResult<void, Error, IUpdateUserPayload> => {
  const queryClient = useQueryClient();

  return useMutation(usersRequests.updateUser, {
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EUsersQueryKey.USERS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useUpdateUserPermission = (
  options?: UseMutationOptions<void, Error, IUpdateUserPermissions>,
): UseMutationResult<void, Error, IUpdateUserPermissions> => {
  const queryClient = useQueryClient();

  return useMutation(usersRequests.updateUserPermission, {
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EUsersQueryKey.USER_PERMISSIONS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useUpdateUserDealershipApplication = (
  options?: UseMutationOptions<void, Error, IUpdateUserDealershipApplication>,
): UseMutationResult<void, Error, IUpdateUserDealershipApplication> => {
  const queryClient = useQueryClient();

  return useMutation(usersRequests.updateUserDealershipPermission, {
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDealershipsQueryKey.DEALERSHIPS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useDeleteUser = (
  options?: UseMutationOptions<void, Error, IUserShortParam>,
): UseMutationResult<void, Error, IUserShortParam> => {
  const queryClient = useQueryClient();

  return useMutation(usersRequests.deleteUser, {
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EUsersQueryKey.USERS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

export const useUsersQueryHooks = {
  useGetUsers,
  useCreateUser,
  useCurrentUser,
  useGetUserPermissions,
  useGetUserDealershipApplication,
  useUpdateUserDealershipApplication,
  useUpdateUser,
  useSearchUser,
  useGetUser,
  useDeleteUser,
  useUpdateUserPermission,
};
