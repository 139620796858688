export enum EAdminPageLinks {
  ADMIN = '/admin',
  CUSTOMERS = '/admin/customers',
  VERIFY_USER = 'VERIFY_USER',
  CUSTOMER = '/admin/customer',
  CURRENT_CUSTOMER = '/admin/current-customer',
  ADD_CUSTOMER = '/admin/add-customer',
  EDIT_CUSTOMER = '/admin/edit-customer',
  EDIT_APPLICATION = '/edit-application',
  USERS = '/admin/users',
  DOCUMENTS = '/admin/documents',
  ADD_USER = '/admin/users/add-user',
  EDIT_USER = '/admin/users/edit-user',
  DOCUMENT_PREVIEW = '/admin/document-preview',
  FILE_UPLOAD = '/admin/file-uploads',
  FILE_UPLOAD_RESULTS = '/admin/file-uploads/results',
  SEARCH_TERM = '/admin/search-term',
  CUSTOMER_ERRORS = '/admin/resolve-errors',
  APPLICATION_ERRORS = '/admin/resolve-application-errors',
  ERRORS = '/admin/errors',
}
