import { Components, Theme } from '@mui/material';

export function Container(theme: Theme): Components {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          [theme.breakpoints.between('lg', 'xl')]: {
            maxWidth: '64rem',
            paddingLeft: 0,
            paddingRight: 0,
          },
          [theme.breakpoints.up('xl')]: {
            maxWidth: '80rem',
            paddingLeft: 0,
            paddingRight: 0,
          },
          [theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  };
}
