import React from 'react';
import { Components, Theme } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '@assets/Down.svg';

export function Accordion(theme: Theme): Components {
  return {
    MuiAccordion: {
      defaultProps: {
        TransitionProps: {
          unmountOnExit: true,
        },
      },
      styleOverrides: {
        rounded: {
          borderRadius: 0,
          border: 'none',
        },
        root: {
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ArrowDownIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: '2rem',
          '&.Mui-expanded': {
            minHeight: '2rem',
          },
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '1rem 0 0',
        },
      },
    },
  };
}
