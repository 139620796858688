import React, { PropsWithChildren } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '@/theme/theme';

import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';

export const ThemeRegistryProvider = ({ children }: PropsWithChildren) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ModalProvider rootComponent={TransitionGroup}>{children}</ModalProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};
