import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import { fetcher } from '@/api/fetcher';
import {
  ICrudLogEntity,
  IDocumentLogEntity,
  IGetDocumentHistoryParams,
  IHistoryParams,
  ILoginLogEntity,
  IUploadLogEntity,
  IUsersLogEntity,
} from '@/api/requests/types/history.types';
import { CUSTOMERS_URL_BASE_URL } from '@/api/requests/customers.request';

const BASE_URL = '/history';

const getCrudActionsLog = async ({
  pagination,
  filter,
}: IHistoryParams): Promise<IResponseWithPagination<ICrudLogEntity>> => {
  return await fetcher.get(`${BASE_URL}/crud`, {
    params: {
      ...filter,
      ...pagination,
      ...(filter.customerId === 0 && { customerId: null }),
    },
  });
};

const getLoginLog = async ({
  pagination,
  filter,
}: IHistoryParams): Promise<IResponseWithPagination<ILoginLogEntity>> => {
  return await fetcher.get(`${BASE_URL}/login`, {
    params: {
      ...filter,
      ...pagination,
      ...(filter.customerId === 0 && { customerId: null }),
    },
  });
};

const getUsersLog = async ({
  pagination,
  filter,
}: IHistoryParams): Promise<IResponseWithPagination<IUsersLogEntity>> => {
  return await fetcher.get(`${BASE_URL}/users`, {
    params: {
      ...filter,
      ...pagination,
      ...(filter.customerId === 0 && { customerId: null }),
    },
  });
};

const getUploadLog = async ({
  pagination,
  filter,
}: IHistoryParams): Promise<IResponseWithPagination<IUploadLogEntity>> => {
  return await fetcher.get(`${BASE_URL}/upload`, {
    params: {
      ...filter,
      ...pagination,
      ...(filter.customerId === 0 && { customerId: null }),
    },
  });
};

const getDocumentsLog = async ({
  pagination,
  filter,
}: IHistoryParams): Promise<IResponseWithPagination<IDocumentLogEntity>> => {
  return await fetcher.get(`${BASE_URL}/documents`, {
    params: {
      ...filter,
      ...pagination,
      ...(filter.customerId === 0 && { customerId: null }),
    },
  });
};

const getDocumentLog = async ({
  customerId,
  applicationId,
  documentId,
  pagination,
}: IGetDocumentHistoryParams): Promise<IResponseWithPagination<IDocumentLogEntity>> => {
  return await fetcher.get(
    `${BASE_URL}${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}/documents/${documentId}`,
    {
      params: {
        ...pagination,
      },
    },
  );
};

const saveLoginSuccess = async (): Promise<void> => {
  return await fetcher.put(`${BASE_URL}/login`);
};

export const historyRequests = {
  saveLoginSuccess,
  getCrudActionsLog,
  getDocumentLog,
  getLoginLog,
  getDocumentsLog,
  getUploadLog,
  getUsersLog,
};
