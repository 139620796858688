import { Components, Theme } from '@mui/material';

export function Skeleton(theme: Theme): Components {
  return {
    MuiSkeleton: {
      defaultProps: {
        variant: 'rectangular',
      },
      styleOverrides: {
        root: {
          background: theme.palette.grey[100],
          width: '100%',
          margin: '0 !important',
        },
        rounded: {
          borderRadius: '.5rem',
        },
      },
    },
  };
}
