import React, { PropsWithChildren, FC, useMemo } from 'react';
import { AppState, Auth0Provider as Auth0, CacheLocation } from '@auth0/auth0-react';
import { AuthenticatedLayout } from '@/components/layout/authenticated.layout';
import { redirect } from 'react-router-dom';
import { historyRequests } from '@/api/requests/history.requests';
import { usersRequests } from '@/api/requests/users.request';
import { EAdminPageLinks } from '@/enums/admin-page-links';

export const Auth0Provider: FC<PropsWithChildren> = ({ children }) => {
  const onRedirectCallback = async (appState?: AppState): Promise<void> => {
    const url = appState?.returnTo ? appState.returnTo : window.location.pathname;
    await historyRequests.saveLoginSuccess();
    const currentUser = await usersRequests.getCurrentUser();

    if (currentUser.recaptchaEnabled) {
      redirect(EAdminPageLinks.VERIFY_USER);
      return;
    }

    redirect(url);
  };

  const providerConfig = useMemo(
    () => ({
      domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
      cacheLocation: 'localstorage' as CacheLocation,
      useRefreshTokens: true,
      onRedirectCallback,
      authorizationParams: {
        redirect_uri: typeof window !== 'undefined' ? window.location.origin : '/',
        ...(process.env.REACT_APP_AUTH0_AUDIENCE ? { audience: process.env.REACT_APP_AUTH0_AUDIENCE } : null),
      },
    }),
    [],
  );

  return (
    <Auth0 {...providerConfig}>
      <AuthenticatedLayout>{children}</AuthenticatedLayout>
    </Auth0>
  );
};
