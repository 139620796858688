import { fetcher } from '@/api/fetcher';
import { CUSTOMERS_URL_BASE_URL } from '@/api/requests/customers.request';
import {
  IAppendDocumentPageParams,
  ICreateNote,
  ICustomerApplicationDocumentsParams,
  ICustomerDocuments,
  IDeleteDocumentsByIdsParams,
  IDocumentDetails,
  IDocumentFileUploadParams,
  IDocumentParams,
  IDocumentProcessingParams,
  IDocuments,
  IDocumentsUploadProcessStatistics,
  IDocumentUploadInformation,
  IDocumentUploadParams,
  IEditIndexValuesParams,
  IExportAllIDocumentsParams,
  IExportDocumentsParams,
  IExportDocumentsProcessResponse,
  IExportDocumentsRequestParams,
  IExportProcessed,
  IGetCustomerDocumentsParams,
  IGetDocumentParams,
  IGetDocumentsByIdsParams,
  IGetProcessedDocumentsParams,
  IMergeDuplicateParams,
  INoteEntity,
  IReplacePageParams,
  ISaveDuplicateParams,
  IShareDocumentParams,
  IShareSelectedDocumentsParams,
} from '@/api/requests/types/documents.types';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import { IPaginationParams } from '@/api/requests/types/customer.types';

const BASE_URL = '/documents';

const getDocuments = async ({
  applicationId,
  customerId,
  data,
}: ICustomerApplicationDocumentsParams): Promise<IResponseWithPagination<IDocuments>> => {
  return await fetcher.post(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/search`,
    {
      ...(data.searchWord !== '' && { searchWord: data.searchWord }),
      dealershipIds: data.dealershipIds,
      indexName: data.indexName,
      indexValue: data.indexValue,
      filters: data.filters,
      sortIndexField: data.sortIndexField,
    },
    {
      params: {
        page: data.page,
        size: data.size,
        sort: data.sort,
      },
    },
  );
};

const getExportDocumentsStatus = async ({
  applicationId,
  customerId,
  processId,
}: IExportDocumentsRequestParams): Promise<IExportDocumentsProcessResponse> => {
  return await fetcher.get(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/export/zip/status`,
    {
      params: { processId },
    },
  );
};

const getProcessedDocuments = async ({
  applicationId,
  customerId,
  data,
}: IGetProcessedDocumentsParams): Promise<IResponseWithPagination<IDocuments>> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}`, {
    params: {
      ...data,
    },
  });
};

const getDocumentsByIds = async ({
  applicationId,
  customerId,
  data,
}: IGetDocumentsByIdsParams): Promise<IResponseWithPagination<IDocuments>> => {
  return await fetcher.get(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/indexData`,
    {
      params: {
        ...data,
      },
    },
  );
};

const getDocument = async ({ applicationId, customerId, id }: IGetDocumentParams): Promise<IDocumentDetails> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/${id}`);
};

const getCustomerDocuments = async ({
  customerId,
  data,
}: IGetCustomerDocumentsParams): Promise<IResponseWithPagination<ICustomerDocuments>> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}${BASE_URL}`, {
    params: {
      ...data,
    },
  });
};

const uploadDocument = async ({ url, fileInformation }: IDocumentFileUploadParams): Promise<void> => {
  return await fetcher.put(url, fileInformation.file, {
    headers: {
      'Content-Type': fileInformation.file.type,
      'x-amz-meta-crc32': fileInformation.checksumCRC32,
    },
    skipAuthorization: true,
  });
};

const getUploadDocumentLinks = async ({
  applicationId,
  customerId,
  files,
  folderName,
}: IDocumentUploadParams): Promise<IDocumentUploadInformation[]> => {
  return await fetcher.post(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/upload-links`,
    {
      files,
      folderName,
    },
  );
};

const runUploadProcessing = async ({
  customerId,
  applicationId,
  folderName,
}: IDocumentProcessingParams): Promise<IDocumentsUploadProcessStatistics> => {
  return await fetcher.post(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/process`,
    { folderName },
  );
};

const deleteDocumentByIds = async ({
  customerId,
  applicationId,
  id,
  type,
}: IDeleteDocumentsByIdsParams): Promise<void> => {
  return await fetcher.delete(`${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}`, {
    params: { id, type },
  });
};

const getNotes = async (
  params: IDocumentParams,
  pagination: IPaginationParams,
): Promise<IResponseWithPagination<INoteEntity>> => {
  return await fetcher.get(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}${BASE_URL}/${params.documentId}/notes`,
    {
      params: { ...pagination },
    },
  );
};

const createNote = async ({ params, data }: ICreateNote): Promise<void> => {
  return await fetcher.post(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}${BASE_URL}/${params.documentId}/notes`,
    data,
  );
};

const shareDocument = async ({ params, data }: IShareDocumentParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}${BASE_URL}/${params.documentId}/share`,
    data,
  );
};

const shareSelectedDocuments = async ({ params, data }: IShareSelectedDocumentsParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}${BASE_URL}/share`,
    data,
  );
};

const appendDocumentPage = async ({
  documentId,
  customerId,
  applicationId,
  data,
}: IAppendDocumentPageParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/
${documentId}/append`,
    {
      pdfFile: data.pdfFile,
    },
    {
      params: {
        appendType: data.appendType,
        pageNumber: data.pageNumber,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

const replaceDocumentPage = async ({
  documentId,
  customerId,
  applicationId,
  data,
}: IReplacePageParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${customerId}/applications/${applicationId}${BASE_URL}/
${documentId}/replace`,
    {
      pdfFile: data.pdfFile,
    },
    {
      params: {
        pageNumber: data.pageNumber,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

const updateIndexes = async ({ params, data }: IEditIndexValuesParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/${params.documentId}/indexData`,
    data,
  );
};

const saveDuplicate = async ({ params, data }: ISaveDuplicateParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/duplicates/save`,
    data,
  );
};

const mergeDuplicate = async ({ params, data }: IMergeDuplicateParams): Promise<void> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/duplicates/merge`,
    data,
  );
};

const exportToExcel = async ({ params, data }: IExportDocumentsParams): Promise<Blob> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/export/excel`,
    data,
    { responseType: 'blob' },
  );
};

const exportToZip = async ({ params, data, indexNames }: IExportDocumentsParams): Promise<IExportProcessed> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/export/zip`,
    {
      documentIds: data,
      ...(indexNames && { indexNames }),
    },
  );
};

const exportAllToExcel = async ({ params, data }: IExportAllIDocumentsParams): Promise<Blob> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/export/excel/all`,
    data,
    { responseType: 'blob' },
  );
};

const exportAllToZip = async ({ params, data, indexNames }: IExportAllIDocumentsParams): Promise<IExportProcessed> => {
  return await fetcher.put(
    `${CUSTOMERS_URL_BASE_URL}/${params.customerId}/applications/
${params.applicationId}/documents/export/zip/all`,
    {
      documentSearchParam: {
        ...data,
      },
      ...(indexNames && { indexNames }),
    },
  );
};

export const documentsRequests = {
  getDocuments,
  getCustomerDocuments,
  uploadDocument,
  getDocument,
  updateIndexes,
  exportAllToExcel,
  exportAllToZip,
  appendDocumentPage,
  createNote,
  shareDocument,
  shareSelectedDocuments,
  mergeDuplicate,
  exportToZip,
  getProcessedDocuments,
  saveDuplicate,
  deleteDocumentByIds,
  getExportDocumentsStatus,
  getDocumentsByIds,
  replaceDocumentPage,
  exportToExcel,
  getNotes,
  runUploadProcessing,
  getUploadDocumentLinks,
};
