import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as DarkLogo } from '@assets/LogoDarkj.svg';
import { theme } from '@/theme/theme';
import { Box, Card, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const VerifyUser = () => {
  const [loader, setLoader] = useState(true);
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const changeReCaptcha = (token: string | null) => {
    if (!token) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      return;
    }

    navigate('/', { replace: true });
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        height: '100svh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem',
        flexDirection: 'column',
      }}
    >
      <Card sx={{ textAlign: 'center', minWidth: '21rem' }}>
        <Box sx={{ margin: '1rem 0 .5rem' }}>
          <DarkLogo />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loader && <CircularProgress size="2.5rem" />}
          <ReCAPTCHA
            asyncScriptOnLoad={(): void => setLoader(false)}
            sitekey={process.env.REACT_APP_SITE_KEY ?? ''}
            onChange={changeReCaptcha}
          />
        </Box>
      </Card>
    </Box>
  );
};
