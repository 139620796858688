import { Components } from '@mui/material';

export function Badge(): Components {
  return {
    MuiBadge: {
      defaultProps: {
        color: 'error',
        variant: 'dot',
      },
    },
  };
}
