import React, { Suspense, ComponentType } from 'react';
import { UiPageLoader } from '@/components/ui/ui-page-loader';

export const withSuspense = <T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) => {
  return (props: T) => (
    <Suspense fallback={<UiPageLoader />}>
      <Component {...props} />
    </Suspense>
  );
};
