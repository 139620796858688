import { Theme } from '@mui/material';
import { Button } from './Button';
import { LoadingButton } from './LoadingButton';
import { ListItemIcon } from './ListItemIcon';
import { ListItemButton } from './ListItemButton';
import { Card } from './Card';
import { Table } from './Table';
import { TablePagination } from './TablePagination';
import { Input } from './Input';
import { Dialog } from './Dialog';
import { Drawer } from './Dawer';
import { Skeleton } from './Skeleton';
import { Tabs } from './Tabs';
import { Accordion } from '@/theme/overrides/Accordion';
import { AppBar } from '@/theme/overrides/AppBar';
import { Badge } from './Badge';
import { Alert } from './Alert';
import { Tooltip } from './Tooltip';
import { Container } from '@/theme/overrides/Container';

export function overrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    LoadingButton(),
    ListItemIcon(),
    Skeleton(theme),
    Card(theme),
    Container(theme),
    Tooltip(theme),
    Dialog(theme),
    Tabs(theme),
    ListItemButton(theme),
    Table(theme),
    Drawer(theme),
    TablePagination(theme),
    Input(theme),
    AppBar(theme),
    Accordion(theme),
    Badge(),
    Alert(theme),
  );
}
