export const UPLOAD_TXT_VALIDATION_CONFIG = 'text/plain';
export const UPLOAD_CSV_VALIDATION_CONFIG = 'text/csv';
export const EXPORT_ZIP = 'application/zip';
export const ACCEPT_UPLOAD_FILE_TYPE = 'application/pdf';
export const IMAGE_HEIF = 'image/heif';

export const ALLOWED_UPLOAD_FILE_TYPES = [
  ACCEPT_UPLOAD_FILE_TYPE,
  UPLOAD_CSV_VALIDATION_CONFIG,
  UPLOAD_TXT_VALIDATION_CONFIG,
];

export const ACCEPT_APPEND_FILE_TYPE_ERROR = [
  'application/pdf',
  'image/png',
  'image/jpg',
  'image/*',
  'image/jpeg',
  'image/heif',
  'image/heic',
  '.heic',
  'heic',
  '.HEIC',
];
