import { Components, Theme } from '@mui/material';
import { ReactComponent as RightArrow } from '@assets/Right.svg';
import { ReactComponent as LeftArrow } from '@assets/Left.svg';

export function Tabs(theme: Theme): Components {
  return {
    MuiTabs: {
      defaultProps: {
        variant: 'fullWidth',
        allowScrollButtonsMobile: false,
        slots: {
          StartScrollButtonIcon: LeftArrow,
          EndScrollButtonIcon: RightArrow,
        },
      },
      styleOverrides: {
        flexContainer: {
          borderRadius: '0.5rem',
          border: `1px solid ${theme.palette.grey[600]}`,
          background: theme.palette.grey[100],
          padding: '0.25rem 0',
        },
        scroller: {
          borderRadius: '0.5rem',
          border: `1px solid ${theme.palette.grey[600]}`,
          background: theme.palette.grey[100],
          padding: '0.25rem 0.25rem',
          '& > .MuiTabs-flexContainer': {
            padding: 0,
            border: 'none',
            background: 'transparent',
          },
        },
        indicator: {
          top: 0,
          bottom: 0,
          margin: '0.25rem 0',
          borderRadius: '0.25rem',
          background: theme.palette.background.paper,
          boxShadow: theme.elevation[5],
          height: 'inherit',
          zIndex: 1,
        },
        scrollButtons: {
          color: theme.palette.text.primary,
          opacity: 1,
          '&> svg > path': {
            color: 'currentColor',
            stroke: 'currentColor',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '12.5rem',
          width: 'auto',
          maxWidth: 'auto',
          padding: '0.5rem',
          color: theme.palette.text.primary,
          textTransform: 'none',
          fontWeight: 400,
          zIndex: 2,
          minHeight: '2.5rem',

          [theme.breakpoints.down('sm')]: {
            minWidth: '10rem',
          },
        },
      },
    },
  };
}
