import React, { createContext, FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useUsersQueryHooks } from '@/api/query-hooks/users.query-hooks';
import { UiPageLoader } from '@/components/ui/ui-page-loader';
import { EUserRole, ICurrentUserPermission, IUserEntity } from '@/api/requests/types/user.types';
import { TCurrentPermit } from '@/types/user-permit';
import { useErrorsQueryHooks } from '@/api/query-hooks/erorrs.query-hooks';
import { EUserPermission } from '@/types/user-permission';
import { useCustomersQueryHooks } from '@/api/query-hooks/customers.query-hooks';

interface ICurrentUserProviderReturn {
  user: IUserEntity | null;
  customerLogo: string;
  permissions: ICurrentUserPermission[] | null;
  isExistsErrors: boolean;
  isUserHasRole: (currentRole?: TCurrentPermit) => boolean;
  isUserHasPermission: (permission: EUserPermission, applicationId?: string) => boolean;
}

const CurrentUserContext = createContext<ICurrentUserProviderReturn>({
  user: null,
  permissions: null,
  customerLogo: '',
  isExistsErrors: false,
  isUserHasRole: () => false,
  isUserHasPermission: () => false,
});

const CurrentUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: user, isLoading, isRefetching } = useUsersQueryHooks.useCurrentUser();
  const { data: errors } = useErrorsQueryHooks.useErrorsExists(user?.role, {
    cacheTime: 0,
  });
  const isGetCustomerLogo = user?.role === EUserRole.USER || user?.role === EUserRole.CUSTOMER_ADMIN;

  const { data: customerLogo } = useCustomersQueryHooks.useGetCustomerLogo({
    cacheTime: 0,
    enabled: isGetCustomerLogo,
  });

  const isVisibleLoader = isLoading || isRefetching;

  const isUserHasRole = useCallback(
    (currentRole?: TCurrentPermit): boolean => {
      if (!user?.role) return false;

      if (!Array.isArray(currentRole)) {
        return user?.role === currentRole;
      }

      return currentRole.includes(user?.role);
    },
    [user?.role],
  );

  const isUserHasPermission = useCallback(
    (permission: EUserPermission, applicationId?: string): boolean => {
      if (user?.role === EUserRole.ADT_ADMIN) {
        return true;
      }

      if (!user?.applicationIdPermissions) {
        return false;
      }

      if (applicationId) {
        return user?.applicationIdPermissions
          .filter((item) => item.applicationId === Number(applicationId))
          .flatMap((item) => item.permissions)
          .includes(permission);
      }

      return user?.applicationIdPermissions.flatMap((item) => item.permissions).includes(permission);
    },
    [user?.role, user?.applicationIdPermissions],
  );

  const value = useMemo((): ICurrentUserProviderReturn => {
    return {
      user: user ?? null,
      permissions: user?.applicationIdPermissions ?? null,
      isUserHasRole,
      isUserHasPermission,
      customerLogo: customerLogo?.url ?? '',
      isExistsErrors: errors?.exists ?? false,
    };
  }, [user, errors, customerLogo, isUserHasRole, isUserHasPermission]);

  if (isVisibleLoader) {
    return <UiPageLoader />;
  }

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
};

export { CurrentUserProvider, CurrentUserContext };
