import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { documentsRequests } from '@/api/requests/documents.requests';
import {
  IAppendDocumentPageParams,
  ICreateNote,
  ICustomerApplicationDocumentsParams,
  ICustomerDocuments,
  IDeleteDocumentsByIdsParams,
  IDocumentDetails,
  IDocumentFileUploadParams,
  IDocumentParams,
  IDocumentProcessingParams,
  IDocuments,
  IDocumentsUploadProcessStatistics,
  IDocumentUploadInformation,
  IDocumentUploadParams,
  IEditIndexValuesParams,
  IExportAllIDocumentsParams,
  IExportDocumentsParams,
  IExportDocumentsProcessResponse,
  IExportDocumentsRequestParams,
  IExportProcessed,
  IGetCustomerDocumentsParams,
  IGetDocumentParams,
  IGetDocumentsByIdsParams,
  IGetProcessedDocumentsParams,
  IMergeDuplicateParams,
  INoteEntity,
  IReplacePageParams,
  ISaveDuplicateParams,
  IShareDocumentParams,
  IShareSelectedDocumentsParams,
  IUpdateIndexesError,
} from '@/api/requests/types/documents.types';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import { EDocumentsQueryKey } from '@/api/query-keys/documents.query-keys';
import { IPaginationParams } from '@/api/requests/types/customer.types';
import { EErrorsQueryKey } from '@/api/query-keys/errors.query-keys';
import { EHistoryQueryKey } from '@/api/query-keys/history.query-keys';
import { AxiosError } from 'axios';
import { EExportProcessStatus } from '@/domains/document/types/document.types';

const useGetDocuments = (
  { customerId, applicationId, data }: ICustomerApplicationDocumentsParams,
  options?: UseQueryOptions<IResponseWithPagination<IDocuments>, Error, IResponseWithPagination<IDocuments>, string[]>,
) => {
  return useQuery(
    [EDocumentsQueryKey.DOCUMENTS, `${customerId}`, `${applicationId}`, JSON.stringify(data)],
    () => documentsRequests.getDocuments({ applicationId, customerId, data }),
    {
      enabled: !!applicationId && !!customerId,
      ...options,
    },
  );
};

const useGetProcessedDocuments = (
  { customerId, applicationId, data }: IGetProcessedDocumentsParams,
  options?: UseQueryOptions<IResponseWithPagination<IDocuments>, Error, IResponseWithPagination<IDocuments>, string[]>,
) => {
  return useQuery(
    [EDocumentsQueryKey.DOCUMENTS, `${customerId}`, `${applicationId}`, JSON.stringify(data)],
    () => documentsRequests.getProcessedDocuments({ applicationId, customerId, data }),
    {
      enabled: !!applicationId && !!customerId,
      ...options,
    },
  );
};

const useGetDocumentsByIds = (
  { customerId, applicationId, data }: IGetDocumentsByIdsParams,
  options?: UseQueryOptions<IResponseWithPagination<IDocuments>, Error, IResponseWithPagination<IDocuments>, string[]>,
) => {
  return useQuery(
    [EDocumentsQueryKey.DOCUMENTS, `${customerId}`, `${applicationId}`, JSON.stringify(data)],
    () => documentsRequests.getDocumentsByIds({ applicationId, customerId, data }),
    {
      enabled: !!applicationId && !!customerId,
      ...options,
    },
  );
};

const useGetDocument = (
  { customerId, applicationId, id }: IGetDocumentParams,
  options?: UseQueryOptions<IDocumentDetails, Error, IDocumentDetails, string[]>,
) => {
  return useQuery(
    [EDocumentsQueryKey.DOCUMENTS, `${customerId}`, `${applicationId}`, `${id}`],
    () => documentsRequests.getDocument({ customerId, applicationId, id }),
    {
      enabled: !!applicationId && !!customerId && !!id,
      ...options,
    },
  );
};

const useGetExportDocumentsStatus = (
  data: IExportDocumentsRequestParams[],
  options?: UseQueryOptions<IExportDocumentsProcessResponse, AxiosError, IExportDocumentsProcessResponse, string[]>,
) => {
  return useQueries({
    queries: data.map((process) => {
      const { customerId, applicationId, processId } = process;
      return {
        queryKey: [EDocumentsQueryKey.DOCUMENTS, `${customerId}`, `${applicationId}`, `${processId}`],
        queryFn: () => documentsRequests.getExportDocumentsStatus({ customerId, applicationId, processId }),
        enabled: !!customerId && !!applicationId && !!processId,
        cacheTime: 0,
        refetchInterval: (response: IExportDocumentsProcessResponse) => {
          return response &&
            response.status !== EExportProcessStatus.COMPLETED &&
            response.status !== EExportProcessStatus.FAILED
            ? 1000
            : false;
        },
      } as UseQueryOptions<IExportDocumentsProcessResponse, AxiosError, IExportDocumentsProcessResponse, string[]>;
    }),
  });
};

const useGetNotes = (
  params: IDocumentParams,
  pagination: IPaginationParams,
  options?: UseQueryOptions<
    IResponseWithPagination<INoteEntity>,
    Error,
    IResponseWithPagination<INoteEntity>,
    string[]
  >,
) => {
  return useQuery(
    [EDocumentsQueryKey.NOTES, JSON.stringify(params), JSON.stringify(pagination)],
    () => documentsRequests.getNotes(params, pagination),
    {
      enabled: !!params.documentId && !!params.customerId && !!params.applicationId,
      ...options,
    },
  );
};

const useGetCustomerDocuments = (
  { customerId, data }: IGetCustomerDocumentsParams,
  options?: UseQueryOptions<
    IResponseWithPagination<ICustomerDocuments>,
    Error,
    IResponseWithPagination<ICustomerDocuments>,
    string[]
  >,
) => {
  return useQuery(
    [EDocumentsQueryKey.DOCUMENTS, `${customerId}`, JSON.stringify(data)],
    () => documentsRequests.getCustomerDocuments({ customerId, data }),
    {
      enabled: !!customerId,
      ...options,
    },
  );
};

const useGeInfiniteCustomerDocuments = (
  { customerId, data }: IGetCustomerDocumentsParams,
  options?: UseInfiniteQueryOptions<
    IResponseWithPagination<ICustomerDocuments>,
    Error,
    IResponseWithPagination<ICustomerDocuments>,
    string[]
  >,
) => {
  return useInfiniteQuery(
    [EDocumentsQueryKey.DOCUMENTS, `${customerId}`, JSON.stringify(data)],
    ({ pageParam = 0 }) => documentsRequests.getCustomerDocuments({ customerId, data }),
    {
      enabled: !!customerId,
    },
  );
};

const useUploadDocumentFile = (
  options?: UseMutationOptions<void, Error, IDocumentFileUploadParams>,
): UseMutationResult<void, Error, IDocumentFileUploadParams> => {
  return useMutation(documentsRequests.uploadDocument, options);
};

const useGetUploadDocumentLinks = (
  options?: UseMutationOptions<IDocumentUploadInformation[], Error, IDocumentUploadParams>,
): UseMutationResult<IDocumentUploadInformation[], Error, IDocumentUploadParams> => {
  return useMutation(documentsRequests.getUploadDocumentLinks, options);
};

const useRunUploadProcessing = (
  options?: UseMutationOptions<IDocumentsUploadProcessStatistics, Error, IDocumentProcessingParams>,
): UseMutationResult<IDocumentsUploadProcessStatistics, Error, IDocumentProcessingParams> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.runUploadProcessing, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.DOCUMENTS] });
      await queryClient.resetQueries({ queryKey: [EErrorsQueryKey.ERRORS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useDeleteByDocumentIds = (
  options?: UseMutationOptions<void, Error, IDeleteDocumentsByIdsParams>,
): UseMutationResult<void, Error, IDeleteDocumentsByIdsParams> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.deleteDocumentByIds, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.DOCUMENTS] });
      await queryClient.resetQueries({ queryKey: [EHistoryQueryKey.HISTORY_DOCUMENT] });
      await queryClient.resetQueries({ queryKey: [EErrorsQueryKey.ERRORS] });
      await queryClient.resetQueries({ queryKey: [EErrorsQueryKey.CUSTOMER_ERRORS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useAddNote = (
  options?: UseMutationOptions<void, Error, ICreateNote>,
): UseMutationResult<void, Error, ICreateNote> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.createNote, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.NOTES] });
      await queryClient.resetQueries({ queryKey: [EHistoryQueryKey.HISTORY_DOCUMENT] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useShareDocumentToEmail = (
  options?: UseMutationOptions<void, Error, IShareDocumentParams>,
): UseMutationResult<void, Error, IShareDocumentParams> => {
  return useMutation(documentsRequests.shareDocument, options);
};

const useShareSelectedDocumentsToEmail = (
  options?: UseMutationOptions<void, Error, IShareSelectedDocumentsParams>,
): UseMutationResult<void, Error, IShareSelectedDocumentsParams> => {
  return useMutation(documentsRequests.shareSelectedDocuments, options);
};

const useAppendDocumentFile = (
  options?: UseMutationOptions<void, Error, IAppendDocumentPageParams>,
): UseMutationResult<void, Error, IAppendDocumentPageParams> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.appendDocumentPage, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.DOCUMENTS] });
      await queryClient.resetQueries({ queryKey: [EHistoryQueryKey.HISTORY_DOCUMENT] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useReplaceDocumentFile = (
  options?: UseMutationOptions<void, Error, IReplacePageParams>,
): UseMutationResult<void, Error, IReplacePageParams> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.replaceDocumentPage, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.DOCUMENTS] });
      await queryClient.resetQueries({ queryKey: [EHistoryQueryKey.HISTORY_DOCUMENT] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useUpdateIndexes = (
  options?: UseMutationOptions<void, AxiosError<IUpdateIndexesError>, IEditIndexValuesParams>,
): UseMutationResult<void, AxiosError<IUpdateIndexesError>, IEditIndexValuesParams> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.updateIndexes, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.DOCUMENTS] });
      await queryClient.resetQueries({ queryKey: [EHistoryQueryKey.HISTORY_DOCUMENT] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useSaveDuplicate = (
  options?: UseMutationOptions<void, Error, ISaveDuplicateParams>,
): UseMutationResult<void, Error, ISaveDuplicateParams> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.saveDuplicate, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.DOCUMENTS] });
      await queryClient.resetQueries({ queryKey: [EHistoryQueryKey.HISTORY_DOCUMENT] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useMergeDuplicate = (
  options?: UseMutationOptions<void, Error, IMergeDuplicateParams>,
): UseMutationResult<void, Error, IMergeDuplicateParams> => {
  const queryClient = useQueryClient();

  return useMutation(documentsRequests.mergeDuplicate, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await queryClient.resetQueries({ queryKey: [EDocumentsQueryKey.DOCUMENTS] });
      await queryClient.resetQueries({ queryKey: [EHistoryQueryKey.HISTORY_DOCUMENT] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useExportToExcel = (
  options?: UseMutationOptions<Blob, Error, IExportDocumentsParams>,
): UseMutationResult<Blob, Error, IExportDocumentsParams> => {
  return useMutation(documentsRequests.exportToExcel, options);
};

const useExportToZip = (
  options?: UseMutationOptions<IExportProcessed, Error, IExportDocumentsParams>,
): UseMutationResult<IExportProcessed, Error, IExportDocumentsParams> => {
  return useMutation(documentsRequests.exportToZip, options);
};

const useExportAllToExcel = (
  options?: UseMutationOptions<Blob, Error, IExportAllIDocumentsParams>,
): UseMutationResult<Blob, Error, IExportAllIDocumentsParams> => {
  return useMutation(documentsRequests.exportAllToExcel, options);
};

const useExportAllToZip = (
  options?: UseMutationOptions<IExportProcessed, Error, IExportAllIDocumentsParams>,
): UseMutationResult<IExportProcessed, Error, IExportAllIDocumentsParams> => {
  return useMutation(documentsRequests.exportAllToZip, options);
};

export const useDocumentsQueryHooks = {
  useGetCustomerDocuments,
  useRunUploadProcessing,
  useDeleteByDocumentIds,
  useAppendDocumentFile,
  useUpdateIndexes,
  useReplaceDocumentFile,
  useGetDocuments,
  useExportToExcel,
  useExportToZip,
  useSaveDuplicate,
  useMergeDuplicate,
  useGetExportDocumentsStatus,
  useExportAllToExcel,
  useUploadDocumentFile,
  useGetDocument,
  useShareSelectedDocumentsToEmail,
  useExportAllToZip,
  useGetProcessedDocuments,
  useShareDocumentToEmail,
  useGetDocumentsByIds,
  useGeInfiniteCustomerDocuments,
  useGetUploadDocumentLinks,
  useGetNotes,
  useAddNote,
};
