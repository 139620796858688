import {
  useQuery,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import {
  ICreateCustomerPayload,
  ICustomerEntity,
  ICustomer,
  IUpdateCustomerPayload,
  IPaginationParams,
  IGetDealershipsParams,
  IGetApplicationsParams,
  ISearchDealershipsParams,
  ISearchParams,
  ICustomerShortEntity,
  ILogoUrl,
  IUploadCustomerLogo,
} from '@/api/requests/types/customer.types';
import { customersRequests } from '@/api/requests/customers.request';
import { IDealershipEntity } from '@/api/requests/types/dealership.types';
import { IApplicationEntity } from '@/api/requests/types/application.types';
import { IGetUserDealershipApplication } from '@/api/requests/types/user.types';
import { ECustomersQueryKey } from '@/api/query-keys/customers.query-keys';
import { EDealershipsQueryKey } from '@/api/query-keys/dealerships.query-keys';
import { EApplicationsQueryKey } from '@/api/query-keys/applications.query-keys';
import { PAGINATION_DEFAULT_PARAMS } from '@/constants/pagination-default-params';
import { IUploadLogoParams } from '@/api/requests/types/documents.types';

const useGetCustomers = (
  data: IPaginationParams,
  options?: UseQueryOptions<IResponseWithPagination<ICustomer>, Error, IResponseWithPagination<ICustomer>, string[]>,
) => {
  return useQuery(
    [ECustomersQueryKey.CUSTOMERS, JSON.stringify(data)],
    () => customersRequests.getCustomers(data),
    options,
  );
};

const useGetCustomer = (
  customerId?: string,
  options?: UseQueryOptions<ICustomerEntity, Error, ICustomerEntity, string[]>,
) => {
  return useQuery(
    [ECustomersQueryKey.CUSTOMERS, ECustomersQueryKey.CUSTOMER, customerId ?? ''],
    () => customersRequests.getCustomer(customerId!),
    {
      enabled: !!customerId,
      ...options,
    },
  );
};

const useGetCustomerLogo = (options?: UseQueryOptions<ILogoUrl, Error, ILogoUrl, string[]>) => {
  return useQuery([ECustomersQueryKey.CUSTOMERS, 'logo'], customersRequests.getCustomerLogo, {
    ...options,
  });
};

const useGetCurrentCustomer = (options?: UseQueryOptions<ICustomerEntity, Error, ICustomerEntity, string[]>) => {
  return useQuery(
    [ECustomersQueryKey.CUSTOMERS, ECustomersQueryKey.CUSTOMER, 'current'],
    customersRequests.getCurrentCustomer,
    {
      ...options,
    },
  );
};

const useSearchCustomerDealership = (
  data: ISearchDealershipsParams,
  options?: UseQueryOptions<IDealershipEntity[], Error, IDealershipEntity[], string[]>,
) => {
  return useQuery(
    [EDealershipsQueryKey.DEALERSHIPS, ECustomersQueryKey.SEARCH_CUSTOMER_DEALERSHIPS, JSON.stringify(data)],
    () => customersRequests.searchCustomerDealership(data),
    {
      enabled: !!data.params.searchWord,
      ...options,
    },
  );
};

const useSearchCustomer = (
  params: ISearchParams,
  options?: UseQueryOptions<ICustomerShortEntity[], Error, ICustomerShortEntity[], string[]>,
) => {
  return useQuery(
    [ECustomersQueryKey.CUSTOMERS, ECustomersQueryKey.SEARCH_CUSTOMER, JSON.stringify(params)],
    () => customersRequests.searchCustomer(params),
    {
      enabled: !!params.searchWord,
      ...options,
    },
  );
};

const useGetDealershipsByCustomerId = (
  params: IGetDealershipsParams,
  options?: UseQueryOptions<
    IResponseWithPagination<IDealershipEntity>,
    Error,
    IResponseWithPagination<IDealershipEntity>,
    string[]
  >,
) => {
  return useQuery(
    [EDealershipsQueryKey.DEALERSHIPS, ECustomersQueryKey.CUSTOMER_DEALERSHIPS, JSON.stringify(params)],
    () => customersRequests.getDealershipsByCustomerId(params),
    options,
  );
};

const useGetApplicationsByCustomerId = (
  params: IGetApplicationsParams,
  options?: UseQueryOptions<
    IResponseWithPagination<IApplicationEntity>,
    Error,
    IResponseWithPagination<IApplicationEntity>,
    string[]
  >,
) => {
  return useQuery(
    [EApplicationsQueryKey.APPLICATIONS, ECustomersQueryKey.CUSTOMER_APPLICATIONS, JSON.stringify(params)],
    () => customersRequests.getApplicationsByCustomerId(params),
    {
      enabled: !!params.customerId,
      ...options,
    },
  );
};

const useGetCustomerDealershipApplication = (
  customerId: number | null,
  options?: UseQueryOptions<IGetUserDealershipApplication[], Error, IGetUserDealershipApplication[], string[]>,
) => {
  return useQuery(
    [EDealershipsQueryKey.DEALERSHIPS, ECustomersQueryKey.CUSTOMER_DEALERSHIP_APPLICATION, `${customerId}`],
    () =>
      customersRequests.getCustomerDealershipPermission(customerId ?? 0, {
        ...PAGINATION_DEFAULT_PARAMS,
      }),
    {
      enabled: !!customerId,
      ...options,
    },
  );
};

const useCreateCustomer = (
  options?: UseMutationOptions<void, Error, ICreateCustomerPayload>,
): UseMutationResult<void, Error, ICreateCustomerPayload> => {
  const client = useQueryClient();

  return useMutation(customersRequests.createCustomer, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await client.resetQueries({ queryKey: [ECustomersQueryKey.CUSTOMERS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useUpdateCustomer = (
  options?: UseMutationOptions<void, Error, IUpdateCustomerPayload>,
): UseMutationResult<void, Error, IUpdateCustomerPayload> => {
  const client = useQueryClient();

  return useMutation(customersRequests.updateCustomer, {
    ...options,
    onSuccess: async (...onSuccessParams) => {
      await client.resetQueries({ queryKey: [ECustomersQueryKey.CUSTOMERS] });
      options?.onSuccess?.(...onSuccessParams);
    },
  });
};

const useGetUploadLogoLinks = (
  fileName?: string,
  options?: UseQueryOptions<IUploadCustomerLogo, Error, IUploadCustomerLogo, string[]>,
) => {
  return useQuery([`${ECustomersQueryKey.CUSTOMERS}`], () => customersRequests.getUploadLogoLinks({ fileName }), {
    enabled: !!fileName,
    ...options,
  });
};

const useUploadLogoFile = (
  options?: UseMutationOptions<void, Error, IUploadLogoParams>,
): UseMutationResult<void, Error, IUploadLogoParams> => {
  return useMutation(customersRequests.uploadLogo, options);
};

export const useCustomersQueryHooks = {
  useGetCustomers,
  useGetCustomer,
  useCreateCustomer,
  useSearchCustomerDealership,
  useUpdateCustomer,
  useGetCurrentCustomer,
  useUploadLogoFile,
  useGetCustomerDealershipApplication,
  useGetDealershipsByCustomerId,
  useGetApplicationsByCustomerId,
  useSearchCustomer,
  useGetUploadLogoLinks,
  useGetCustomerLogo,
};
