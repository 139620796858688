import React, { useContext } from 'react';
import { CurrentUserContext } from '@/providers/current-user.provider';
import { EUserRole } from '@/api/requests/types/user.types';
import { Navigate } from 'react-router-dom';
import { EAdminPageLinks } from '@/enums/admin-page-links';
import { EUserPageLinks } from '@/enums/user-page-links';

export const RoleBasedRedirect = () => {
  const { isUserHasRole, user } = useContext(CurrentUserContext);
  const isAccessToSuperAdminRoutes = isUserHasRole(EUserRole.ADT_ADMIN);
  const isAccessToUploadRoutes = isUserHasRole(EUserRole.UPLOADER);
  const isAccessToAdminRoutes = isUserHasRole(EUserRole.CUSTOMER_ADMIN) && !!user?.customerId;
  const isAccessToUserRoutes = isUserHasRole(EUserRole.USER) && !!user?.customerId;

  if (user?.recaptchaEnabled && !localStorage.getItem('_grecaptcha')) {
    return <Navigate to={'/verify-user'} replace />;
  }

  if (isAccessToSuperAdminRoutes) {
    return <Navigate to={EAdminPageLinks.CUSTOMERS} replace />;
  }

  if (isAccessToAdminRoutes) {
    return <Navigate to={`${EAdminPageLinks.CURRENT_CUSTOMER}/${user?.customerId}`} replace />;
  }

  if (isAccessToUploadRoutes) {
    return <Navigate to={EAdminPageLinks.FILE_UPLOAD} replace />;
  }

  if (isAccessToUserRoutes) {
    return <Navigate to={EUserPageLinks.DOCUMENTS} replace />;
  }

  return <Navigate to={'/access-denied'} replace />;
};
