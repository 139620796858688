import axios from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { InterceptorOptions } from '@/types/interceptors';
import Qs from 'qs';

export const API_BASE_URL = process.env.REACT_APP_API_URL ?? '/api';
export const CURRENT_API_VERSION = '/api/v1';
const fetcher = axios.create({
  baseURL: `${API_BASE_URL}${CURRENT_API_VERSION}`,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const setupInterceptor = ({ getAccessTokenSilently, logout, onError }: InterceptorOptions): void => {
  const errorHandler = (error: AxiosError): Promise<undefined | never> => {
    const response = error?.response;

    if (response?.status === 401) logout?.();

    const responseData = response?.data || ({} as any);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const msg =
      responseData?.message ||
      responseData.detail ||
      responseData.error?.message ||
      responseData?.error ||
      Object.values(responseData)
        .flatMap((item: any) => item?.error)
        ?.join('\n');
    const errorsToSkip = ['Can not find report'];
    const isErrorsToSkip = errorsToSkip.some((errorToSkip): boolean => msg?.includes(errorToSkip));
    onError(!isErrorsToSkip ? msg : 'Request failed. Please try again or contact us');
    return Promise.reject(error);
  };

  const responseHandler = (response: AxiosResponse): AxiosResponse => {
    if (response?.status === 200) return response?.data;

    return response;
  };

  fetcher.interceptors.response.use(responseHandler, errorHandler);

  const requestHandler = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (config?.skipAuthorization) {
      return config;
    }

    const token = await getAccessTokenSilently?.().catch((): void => {
      onError('Access token not found');
    });

    if (config?.headers && token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fetcher.interceptors.request.use(requestHandler, errorHandler);
};

export { fetcher };
