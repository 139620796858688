import { PaletteOptions } from '@mui/material';

export const themeColors: PaletteOptions = {
  primary: {
    main: '#005386',
    light: '#9BA5C6',
  },
  secondary: {
    main: '#AAC0D3',
  },
  background: {
    default: '#F3F8FE',
    paper: '#fff',
  },
  info: {
    main: '#2A3138',
  },
  success: {
    main: '#5DD99D',
  },
  warning: {
    main: '#FF8F3F',
  },
  error: {
    main: '#D82B58',
    dark: '#FFB8B8',
    light: '#FFF1F1',
  },
  text: {
    primary: '#0F1317',
    secondary: '#A2B3C4',
    disabled: '#C5CED2',
  },
  grey: {
    200: '#161616',
    300: '#F6F6F9',
    500: '#ECECEE',
    700: '#CCCED0',
    100: '#F1F2FB',
    600: '#E4E5F4',
    800: '#EAF6FF',
  },
  common: {
    white: '#FFFFFF',
    black: '#0F1317',
  },
};

export const palette: PaletteOptions = {
  mode: 'light',
  ...themeColors,
};
