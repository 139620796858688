import { Components, Theme } from '@mui/material';

export function AppBar(theme: Theme): Components {
  return {
    MuiAppBar: {
      defaultProps: {
        variant: 'elevation',
        position: 'static',
      },
      styleOverrides: {
        root: {
          height: 'auto',
          background: theme.palette.background.paper,
          boxShadow: theme.elevation[1],
          padding: '1rem',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          objectFit: 'contain',
        },
      },
    },
  };
}
