import { Components, Theme } from '@mui/material';

export function Tooltip(theme: Theme): Components {
  return {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
      styleOverrides: {
        tooltip: {
          border: `0.0625rem solid ${theme.palette.grey[600]}`,
          padding: '.5rem',
          background: theme.palette.background.paper,
          borderRadius: '.5rem',
          color: theme.palette.text.primary,
          fontSize: '0.875rem',
          lineHeight: '1rem',
          fontWeight: 400,
        },
        tooltipPlacementTop: {
          marginBottom: '.5rem !important',
        },
        arrow: {
          width: '.75rem',
          color: theme.palette.grey[600],
        },
      },
    },
  };
}
