import { Components, Theme } from '@mui/material';

export function Dialog(theme: Theme): Components {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: theme.elevation[4],
          borderRadius: '1rem',
          background: theme.palette.background.paper,
          padding: '1rem',
          minWidth: '30rem',
          [theme.breakpoints.down('sm')]: {
            minWidth: '95vw',
            borderRadius: '.5rem',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0 0 1rem',
          borderBottom: `0.09375rem solid ${theme.palette.grey[600]}`,
          [theme.breakpoints.down('sm')]: {
            padding: '0 0 .5rem',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1rem 0 0',
          justifyContent: 'center',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          boxShadow: theme.elevation[4],
          borderRadius: '1rem',
        },
      },
    },
  };
}
