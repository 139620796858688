export enum MainRoutesEnums {
  HOME = '/',
  ADMIN = '/admin/',
}

export enum EAdminRoutes {
  ADMIN = '/admin/*',
  CUSTOMERS = 'customers',
  CUSTOMER = 'customer',
  CURRENT_CUSTOMER = 'current-customer',
  VERIFY_USER = 'verify-user',
  FILE_UPLOAD = 'file-uploads',
  DOCUMENTS = 'documents',
  DOCUMENT_PREVIEW = 'document-preview',
  ADD_CUSTOMER = 'add-customer',
  EDIT_CUSTOMER = 'edit-customer',
  EDIT_APPLICATION = 'edit-application',
  APPLICATION = 'applications',
  LOCATION = 'locations',
  USERS = 'users',
  HISTORY = 'history',
  ADD_USER = 'users/add-user',
  EDIT_USER = 'users/edit-user',
  SEARCH_TERM = 'search-term',
  ERRORS = 'errors',
  RESOLVE_ERRORS = 'resolve-errors',
  UPLOAD_RESULTS = 'results',
  RESOLVE_APPLICATION_ERRORS = 'resolve-application-errors',
}

export enum EUserRoutes {
  USER = '/public/*',
  DOCUMENTS = 'documents',
  SEARCH_TERM = 'search-term',
  ACCESS_DENIED = 'access-denied',
  DOCUMENT_PREVIEW = 'document-preview',
  PRINT = 'print',
}
