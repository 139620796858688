import { IPaginationParams } from '@/api/requests/types/customer.types';

export const PAGINATION_DEFAULT_PARAMS: IPaginationParams = {
  page: 0,
  size: 9999,
  sort: [],
};

export const PAGINATION_DEFAULT_INDEXES_PARAMS: IPaginationParams = {
  page: 0,
  size: 9999,
  sort: ['orderNumber,asc'],
};
