import { fetcher } from '@/api/fetcher';
import { IResponseWithPagination } from '@/api/requests/types/pagination.types';
import {
  ICustomerEntity,
  ICustomer,
  IUpdateCustomerPayload,
  ICreateCustomerPayload,
  IPaginationParams,
  IGetDealershipsParams,
  ISearchDealershipsParams,
  ISearchParams,
  ICustomerShortEntity,
  IGetApplicationsParams,
  ILogoUrl,
  IUploadLogoPayload,
  IUploadCustomerLogo,
} from '@/api/requests/types/customer.types';
import { IDealershipEntity } from '@/api/requests/types/dealership.types';
import { IApplicationEntity } from '@/api/requests/types/application.types';
import { IGetUserDealershipApplication } from '@/api/requests/types/user.types';
import { IUploadLogoParams } from '@/api/requests/types/documents.types';

export const CUSTOMERS_URL_BASE_URL = '/customers';

const getCustomers = async (data: IPaginationParams): Promise<IResponseWithPagination<ICustomer>> => {
  return await fetcher.get(CUSTOMERS_URL_BASE_URL, {
    params: data,
  });
};

const searchCustomerDealership = async (data: ISearchDealershipsParams): Promise<IDealershipEntity[]> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${data.customerId}/dealerships/search`, {
    params: data.params,
  });
};

const searchCustomer = async (params: ISearchParams): Promise<ICustomerShortEntity[]> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/search`, {
    params,
  });
};

const createCustomer = async (data: ICreateCustomerPayload): Promise<void> => {
  return await fetcher.post(CUSTOMERS_URL_BASE_URL, data);
};

const getUploadLogoLinks = async (data: IUploadLogoPayload): Promise<IUploadCustomerLogo> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/logo/upload-link`, {
    params: {
      ...data,
    },
  });
};

const uploadLogo = async ({ url, file }: IUploadLogoParams): Promise<void> => {
  return await fetcher.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
    skipAuthorization: true,
  });
};

const updateCustomer = async ({ id, data }: IUpdateCustomerPayload): Promise<void> => {
  return await fetcher.put(`${CUSTOMERS_URL_BASE_URL}/${id}`, data);
};

const getCustomer = async (customerId: string): Promise<ICustomerEntity> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}`);
};

const getCustomerLogo = async (): Promise<ILogoUrl> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/logo`);
};

const getCurrentCustomer = async (): Promise<ICustomerEntity> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/current`);
};

const getDealershipsByCustomerId = async ({
  customerId,
  paginationParams,
}: IGetDealershipsParams): Promise<IResponseWithPagination<IDealershipEntity>> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}/dealerships`, {
    params: paginationParams,
  });
};

const getApplicationsByCustomerId = async ({
  customerId,
  paginationParams,
}: IGetApplicationsParams): Promise<IResponseWithPagination<IApplicationEntity>> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}/applications`, {
    params: paginationParams,
  });
};

const getCustomerDealershipPermission = async (
  customerId: number,
  data: IPaginationParams,
): Promise<IGetUserDealershipApplication[]> => {
  return await fetcher.get(`${CUSTOMERS_URL_BASE_URL}/${customerId}/applications-dealerships`, {
    params: data,
  });
};

export const customersRequests = {
  getCustomers,
  getDealershipsByCustomerId,
  getApplicationsByCustomerId,
  getUploadLogoLinks,
  uploadLogo,
  getCustomer,
  createCustomer,
  getCurrentCustomer,
  searchCustomerDealership,
  getCustomerDealershipPermission,
  searchCustomer,
  updateCustomer,
  getCustomerLogo,
};
