import { ICustomerParam, IPaginationParams } from '@/api/requests/types/customer.types';
import {
  EAppendDocumentType,
  EDocumentFilterType,
  EExportProcessStatus,
} from '@/domains/document/types/document.types';
import { IUpdateIndexDataPayload } from '@/api/requests/types/errors.types';
import { EIndexSortOrder } from '@/types/sort-order.enum';
import { EIndexType } from '@/api/requests/types/index.types';
import { FileWithPath } from 'file-selector';

export interface ICustomerDocuments {
  id: number;
  applicationName: string;
  resultsCount: number;
}

export type IDocumentIndexData = Record<string, string | number>;

export interface IDocuments {
  id: number;
  dealershipName: string;
  indexData: IDocumentIndexData;
  url: string;
}

export enum EDocumentDeleteType {
  SOFT = 'SOFT',
  HARD = 'HARD',
}

export interface IExportProcessed {
  id: string;
}

export interface IIncorrectRow {
  rowNumber: number;
  row: string;
  message: string;
}

export interface IDocumentsUploadProcessStatistics {
  processed: number;
  totalErrors: number;
  totalPages: number;
  duplicates: number;
  emptyFiles: number;
  incorrectIndexes: number;
  filesWereNotUploaded: number;
  uploadedDocumentIds: number[];
  incorrectRows: IIncorrectRow[];
}

export interface IDocumentProcessingParams {
  customerId: number;
  applicationId: number;
  folderName: string;
}

export interface IDocumentUploadInformation {
  fileName: string;
  uploadUrl: string;
}

export interface IDocumentFileUploadParams {
  url: string;
  fileInformation: IFileWithHash;
}

export interface IUploadLogoParams {
  url: string;
  file: File;
}

export enum EIndexRangeFieldName {
  RANGE_FROM = 'rangeFrom',
  RANGE_TO = 'rangeTo',
}

export enum EExportProcessStorageName {
  EXPORT_PROCESS = 'exportProcess',
}

export interface IFileWithHash {
  file: FileWithPath;
  checksumCRC32: string;
}

export interface IFileInfo {
  fileName: string;
  checksumCRC32: string;
}

export interface IDocumentUploadParams {
  customerId: number | null;
  applicationId: number | null;
  files: IFileInfo[];
  folderName: string;
}

export interface IRangeDto {
  from: string;
  to: string;
}

export interface IDocumentsFilter {
  filterType: EDocumentFilterType | null;
  key: string | null;
  value?: string | null;
  range?: IRangeDto;
  indexType: EIndexType;
}

export interface IIndexSorting {
  indexName: string;
  sortDirection: EIndexSortOrder;
}

export interface IDocumentsParams extends IPaginationParams {
  dealershipIds?: number[];
  indexName?: string;
  indexValue?: string;
  searchWord?: string;
  filters?: IDocumentsFilter[];
  sortIndexField?: IIndexSorting;
}

export interface IGetCustomerDocumentsParams {
  customerId: number | null;
  data: IDocumentsParams | null;
}

export interface ICustomerApplicationDocumentsParams {
  customerId: number | null;
  applicationId: number | null;
  data: IDocumentsParams;
}

export interface IGetDocumentParams {
  customerId: string | null;
  applicationId: string | null;
  id: string | null;
}

export interface IDocumentParams {
  documentId: string | null;
  customerId: string | null;
  applicationId: string | null;
}

export interface ICreateNotePayload {
  note: string;
}

export interface ICreateNote {
  params: IDocumentParams;
  data: ICreateNotePayload;
}

export interface INoteEntity {
  id: number;
  note: string;
  userName: string;
  createdAt: string;
}

export interface IDeleteDocumentsParams extends ICustomerParam {
  applicationId: number;
  id: number;
}

export interface IDeleteDocumentsByIdsParams extends ICustomerParam {
  applicationId: number;
  id: number[];
  type?: EDocumentDeleteType;
}

export interface IDocumentDetails {
  id: number;
  name: string;
  url: string;
  pages: number;
  indexData: IDocumentIndexData;
  dealershipId: number;
  dealershipName: string;
}

export interface IRecipients {
  email: string;
}

export interface IShareDocument {
  recipients: IRecipients[];
  subject: string;
  message: string;
  cc: IRecipients[] | undefined;
  pages: number[];
}

export interface IShareDocumentParams {
  params: IDocumentParams;
  data: IShareDocument;
}

export interface IShareSelectedDocuments {
  recipients: IRecipients[];
  subject: string;
  message: string;
  cc: IRecipients[] | undefined;
  documentIds: number[];
}

export interface IShareSelectedDocumentsParams {
  params: {
    customerId: number | null;
    applicationId: number | null;
  };
  data: IShareSelectedDocuments;
}

export interface IUpdateDocumentFile {
  documentId: string | null;
  customerId: string | null;
  applicationId: string | null;
}

export interface IReplaceDocumentPage {
  pageNumber: number | null;
  pdfFile: File | null;
}

export interface IAppendDocumentPage {
  appendType: EAppendDocumentType;
  pageNumber: number | null;
  pdfFile: File | null;
}

export interface IDefaultDocumentParams {
  customerId: string | null;
  applicationId: string | null;
}

export interface IReplacePageParams extends IUpdateDocumentFile {
  data: IReplaceDocumentPage;
}

export interface IAppendDocumentPageParams extends IUpdateDocumentFile {
  data: IAppendDocumentPage;
}

export interface IEditIndexValuesPayload {
  updateIndexDataDtos: IUpdateIndexDataPayload[];
  dealershipId?: number;
}

export interface IIndexValue {
  id: string;
  key: string;
  value: string | number;
}

export interface IEditIndexValuesParams {
  params: IDocumentParams;
  data: IEditIndexValuesPayload;
}

export interface IUpdateIndexesError {
  error: string;
  duplicateDocumentIds: number[];
}

export interface IExportDocumentsProcessResponse {
  processId: string;
  status: EExportProcessStatus;
  url?: string;
}

export interface IExportDocumentsRequestParams {
  processId: string;
  customerId: string | null;
  applicationId: string | null;
}

export interface ISaveDuplicatePayload {
  documentId: number;
  updateIndexDataDtos: IUpdateIndexDataPayload[];
}

export interface IMergeDuplicatePayload {
  documentId: number;
  duplicateDocumentId?: number;
}

export interface ISaveDuplicateParams {
  params: IDefaultDocumentParams;
  data: ISaveDuplicatePayload;
}

export interface IMergeDuplicateParams {
  params: IDefaultDocumentParams;
  data: IMergeDuplicatePayload;
}

export interface IProcessedDocumentsPayload extends IPaginationParams {
  id: number[];
}

export interface IDocumentsByIdsPayload extends IPaginationParams {
  id: number[];
}

export interface IExportDocumentsParams {
  params: IDefaultDocumentParams;
  data: number[];
  indexNames?: string[];
}

export interface IDocummentsParamsWithoutPagination {
  dealershipIds?: number[];
  indexName?: string;
  indexValue?: string;
  searchWord?: string;
  filters?: IDocumentsFilter[];
}

export interface IExportAllIDocumentsParams {
  params: IDefaultDocumentParams;
  data: IDocummentsParamsWithoutPagination;
  indexNames?: string[];
}

export interface IGetProcessedDocumentsParams {
  customerId: string | null;
  applicationId: string | null;
  data: IProcessedDocumentsPayload;
}

export interface IGetDocumentsByIdsParams {
  customerId: string | null;
  applicationId: string | null;
  data: IDocumentsByIdsPayload;
}
