import React, { PropsWithChildren, ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useAxiosInterceptor } from '@/api/use-axios-interceptor';
import { BaseLayout } from '@/components/layout/base/base.layout';
import { UiPageLoader } from '@/components/ui/ui-page-loader';

export const AuthenticatedLayout = ({ children }: PropsWithChildren): ReactElement => {
  const { isLoading, isAuthenticated, loginWithRedirect, error } = useAuth0();

  useAxiosInterceptor();

  if (error) {
    return (
      <Grid>
        <h1 className="text-red-500">Something went wrong with your account. Refresh the page</h1>
      </Grid>
    );
  }

  if (isLoading) {
    return <UiPageLoader />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();

    return <UiPageLoader />;
  }

  return <BaseLayout>{children}</BaseLayout>;
};
