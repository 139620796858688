import { Components } from '@mui/material';

export function ListItemIcon(): Components {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'inherit',
          '& > svg': {
            color: 'inherit',
            '& > path, & > g, & > g > path': {
              stroke: 'currentColor',
            },
          },
        },
      },
    },
  };
}
