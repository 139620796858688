import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography = (): TypographyOptions => ({
  fontFamily: ['Inter', 'sans-serif'].join(','),
  h1: {
    fontSize: '1.5rem',
    lineHeight: '2.5rem',
    fontWeight: 500,
  },
  h2: {
    fontSize: '1.25rem',
    lineHeight: '2rem',
    fontWeight: 500,
    '@media (min-width:900px)': {
      fontSize: '1.5rem',
    },
  },
  h3: {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
    '@media (max-width:900px)': {
      fontSize: '1.1rem',
    },
  },
  h4: {},
  h5: {},
  h6: {},
  subtitle1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 600,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 500,
  },
  overline: {},
  button: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  buttonMedium: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  buttonSmall: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
});
