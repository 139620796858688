export function LoadingButton() {
  return {
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        disableRipple: true,
      },
    },
  };
}
