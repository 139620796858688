import React, { ReactElement } from 'react';
import { ReactQueryProvider } from '@/providers/react-query/react-query.provider';
import { ThemeRegistryProvider } from '@/providers/theme-registry.provider';
import { Auth0Provider } from '@/providers/auth0.provider';
import { AppRouter } from '@/routes/router';
import './globals.scss';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackbarProvider } from 'notistack';
import { CurrentUserProvider } from '@/providers/current-user.provider';
import { ExportDocumentsProvider } from '@/providers/export-documents.provider';

// TODO Add public layout for application
export const App = (): ReactElement => {
  return (
    <SnackbarProvider
      preventDuplicate={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={5000}
      hideIconVariant={true}
    >
      <Auth0Provider>
        <ReactQueryProvider>
          <CurrentUserProvider>
            <ExportDocumentsProvider>
              <ThemeRegistryProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <AppRouter />
                </LocalizationProvider>
              </ThemeRegistryProvider>
            </ExportDocumentsProvider>
          </CurrentUserProvider>
        </ReactQueryProvider>
      </Auth0Provider>
    </SnackbarProvider>
  );
};
