import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EAdminRoutes, EUserRoutes } from '@/types/main-routes.enum';
import { PrivateRoutesLazy } from '@/routes/private/private-routes';
import { UserRoutesLazy } from '@/routes/private/user-routes';
import { PageNotFoundLazy } from '@/routes/public/pages/page-not-found';
import { AccessDeniedPageLazy } from '@/routes/public/pages/access-denied';
import { RoleBasedRedirect } from '@/routes/role-based-redirect';
import { VerifyUser } from '@/routes/components/verify-user/verify-user';
import { PrintPageLazy } from '@/routes/public/pages/print';

export function AppRouter() {
  return (
    <Routes>
      <Route path={EAdminRoutes.ADMIN} element={<PrivateRoutesLazy />} />
      <Route path={EUserRoutes.USER} element={<UserRoutesLazy />} />
      <Route path={`${EUserRoutes.PRINT}/:customerId/:applicationId`} element={<PrintPageLazy />} />
      <Route path="/access-denied" element={<AccessDeniedPageLazy />} />
      <Route path={EAdminRoutes.VERIFY_USER} element={<VerifyUser />} />
      <Route path="*" element={<PageNotFoundLazy />} />
      <Route index element={<RoleBasedRedirect />} />
    </Routes>
  );
}
